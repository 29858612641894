export const getStyles = ({ theme }) => {
  return {
    sectionCardStyle: {
      mt: 2,
      p: 2,
      border: `1px solid ${theme.palette.primary.main}`,
      transition: 'box-shadow 0.3s',
      '&:hover': {
        boxShadow: '0 4px 6px rgba(17,17,17,0.4)', // Hover effect
      },
    },
  };
};
