import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import organizationAPI from 'api/api';
import { personaType } from 'api/config';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import useNotification from 'hooks/useNotification';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import usePersonaStore from 'store/personaStore';
import useNotificationStore from 'store/useNotificationStore';
import useStore from 'store/useStore';
import BuyerPersonas from './BuyerPersonas';
import SelectedPersona from './SelectedPersona';
import styles from './styles/Style.module.css';

const AIRoleplayCall = () => {
  const [callHistory, setCallHistory] = useState([]);
  const [activeComponent, setActiveComponent] = useState('buyerPersonas');
  const { personas, setPersonas, selectFirstPersona, setBehaviors, behaviors } =
    usePersonaStore();
  const { notification } = useNotificationStore();
  const { show, NotificationComponent } = useNotification();
  const { setPathName } = useStore();
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.loggedUser);

  const handleBackClick = () => {
    navigate(`/${String(user.role).toLocaleLowerCase()}/leaderboard`);
  };

  const { call: getPersonas, isLoading: personaLoading } = useApi({
    fetcher: organizationAPI.getAllPersonas,
    successMessage: 'All persona fetched successfully',
  });

  const { call: getBehaviors, isLoading: behaviorLoading } = useApi({
    fetcher: organizationAPI.getAIBehaviors,
  });

  const {
    call: getCallHistoryUngrouped,
    isLoading: callHistoryUngroupedLoading,
  } = useApi({
    fetcher: organizationAPI.getCallHistoryUngrouped,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPersonas();
      if (response?.data) {
        const filteredPersonas = response.data.filter(
          (persona) => persona.type === personaType
        );
        setPersonas(filteredPersonas);
        selectFirstPersona();
      }
    };
    const fetchBehaviorData = async () => {
      const response = await getBehaviors();
      if (response?.data) {
        setBehaviors(response.data);
      }
    };

    const fetchCallHistoryUngrouped = async () => {
      const response = await getCallHistoryUngrouped();
      if (response?.data) {
        setCallHistory(response.data);
      }
    };
    fetchData();
    fetchBehaviorData();
    fetchCallHistoryUngrouped();
    setPathName(window.location.pathname);
  }, []);

  useEffect(() => {
    if (notification) {
      show(notification.message, notification.type);
    }
  }, [notification, show]);

  const loading =
    personaLoading || behaviorLoading || callHistoryUngroupedLoading;

  return (
    <>
      {!notification && loading ? <LoadingOverlay></LoadingOverlay> : null}
      {notification ? (
        <NotificationComponent
          notification={notification.message}
          type={notification.type}
        />
      ) : null}
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              <h1>AI Roleplay Call </h1>
              <div className={styles.nav_right}>
                <button className={styles.nav_btn2} onClick={handleBackClick}>
                  <ChevronLeftIcon />
                  <p>Return</p>
                </button>
              </div>
            </div>
            <div className={styles.border}></div>
          </div>
          <div className={styles.container}>
            <div className={styles.container_split}>
              <div className={styles.container_left}>
                <div className={styles.left_nav_container}>
                  <div className={styles.left_nav}>
                    <button
                      className={styles.nav_item}
                      style={{
                        borderBottom:
                          activeComponent === 'buyerPersonas'
                            ? '3px solid #00706c'
                            : 'none',
                      }}
                      onClick={() => setActiveComponent('buyerPersonas')}
                    >
                      <PeopleAltIcon />
                      <p>Buyer Personas</p>
                    </button>
                    {/* <button
                      className={styles.nav_item}
                      style={{
                        borderBottom:
                          activeComponent === 'leaderboard'
                            ? '3px solid #00706c'
                            : 'none',
                      }}
                      onClick={() => setActiveComponent('leaderboard')}
                    >
                      <LeaderboardIcon />
                      <p>Leaderboard</p>
                    </button> */}
                  </div>
                  <div className={styles.line} />
                </div>
                <BuyerPersonas
                  data={personas}
                  behaviors={behaviors}
                  callHistory={callHistory}
                />
                {/* {activeComponent === 'buyerPersonas' ? (
                  <BuyerPersonas data={personas} setLoading={setLoading} />
                ) : (
                  <Leaderboard />
                )} */}
              </div>

              <div className={styles.container_right}>
                <SelectedPersona />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIRoleplayCall;
