export const cleanObject = (obj, deep = false) => {
  if (typeof obj !== 'object' || obj === null) return obj;

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    )
      return acc;

    if (deep && typeof value === 'object') {
      const cleanedValue = cleanObject(value, true);
      if (Object.keys(cleanedValue).length !== 0) {
        acc[key] = cleanedValue;
      }
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const omitProps = ({ obj, propsToRemove }) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (!propsToRemove.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
