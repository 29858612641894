import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { EmptyState } from 'components/CustomMUI';
import dateFormat from 'dateformat';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserAssignmentTable = ({ data, filter, search }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getActionButton = (progress, total) => {
    if (progress === 0) return 'Begin';
    if (progress === total) return 'Review';
    return 'Continue';
  };

  const getActionButtonColor = (action) => {
    return action === 'Begin' ? '#1A8753' : '#006298';
  };

  const handleNavigateToAssignmentViewPage = (assignmentId) => {
    navigate(`/convo/assignment/details/${assignmentId}`);
  };

  const filteredData = data
    .filter((row) => filter === 'all' || filter.includes(row.status))
    .filter((row) => {
      if (!search && filter === 'all') return true;

      const searchLower = search.toLowerCase();
      const assignmentNameValid = row.assignmentName
        ? row.assignmentName.toLowerCase().includes(searchLower)
        : false;
      const dueDateValid = row.dueDate
        ? row.dueDate.toLowerCase().includes(searchLower)
        : false;
      const assigneesValid =
        row.assignedTo &&
        row.assignedTo.some((assignee) =>
          assignee.userName.toLowerCase().includes(searchLower)
        );

      return assignmentNameValid || dueDateValid || assigneesValid;
    });

  if (data) {
    return (
      <>
        <Paper
          sx={{
            boxShadow: 'none',
            border: '1px solid #DAE8E7',
            borderRadius: '8px',
            padding: '10px',
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                  >
                    Assignment Name
                  </TableCell>
                  <TableCell
                    sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                  >
                    Due Date
                  </TableCell>
                  <TableCell
                    sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                  >
                    Progress
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const action = getActionButton(row.progress, row.total);
                    return (
                      <TableRow
                        key={row._id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: '#101828',
                            fontSize: '14px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {row.assignmentName}
                        </TableCell>
                        <TableCell sx={{ color: '#101828', fontSize: '14px' }}>
                          {dateFormat(row.dueDate, 'mmm d, yyyy')}
                        </TableCell>
                        <TableCell sx={{ color: '#101828', fontSize: '14px' }}>
                          <Typography
                            sx={{
                              backgroundColor:
                                row.status === 'all'
                                  ? 'rgba(128, 128, 128, 0.5)'
                                  : row.status === 'assigned'
                                  ? 'rgba(64, 153, 255, 0.5)'
                                  : row.status === 'active'
                                  ? 'rgba(0, 200, 117, 0.5)'
                                  : row.status === 'completed'
                                  ? 'rgba(241, 217, 110, 0.5)'
                                  : row.status === 'overdue'
                                  ? 'rgba(253, 97, 97, 0.5)'
                                  : row.status === 'archived'
                                  ? 'rgba(128, 128, 128, 0.5)'
                                  : 'rgba(80, 80, 80, 0.1)',
                              padding: '1px 10px',
                              borderRadius: '12px',
                              width: 'fit-content',
                              fontSize: '14px',
                              textTransform: 'capitalize',
                            }}
                          >
                            {row.status}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              width: '100%',
                              maxWidth: 400,
                            }}
                          >
                            <LinearProgress
                              variant="determinate"
                              value={row.percentage}
                              sx={{
                                width: '100%',
                                height: 8,
                                borderRadius: 4,
                                backgroundColor: '#F2F4F7',
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: '#1A8753',
                                  borderRadius: 4,
                                },
                                border: '1px solid #D8DDE2FF',
                              }}
                            />
                            <Typography
                              sx={{
                                color: '#667085',
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {row.progress}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="text"
                            onClick={() =>
                              handleNavigateToAssignmentViewPage(row._id)
                            }
                            sx={{
                              color: getActionButtonColor(action),
                              fontSize: '14px',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            {row.percentage === 0
                              ? 'Begin'
                              : row.percentage === 100
                              ? 'Review'
                              : 'Continue'}
                            <ChevronRightIcon
                              sx={{ fontSize: 18, color: '#1A8753' }}
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px 16px',
              backgroundColor: '#F8F9FA',
              borderRadius: '8px',
              margin: '16px 0',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Displaying {page * rowsPerPage + 1}-
              {Math.min((page + 1) * rowsPerPage, data.length)} of {data.length}{' '}
              entries
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton
                  size="small"
                  onClick={() => handleChangePage(page - 1)}
                  disabled={page === 0}
                  sx={{ p: 0.5 }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Typography variant="body2" color="text.secondary">
                  Page {page + 1} of {Math.ceil(data.length / rowsPerPage)}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => handleChangePage(page + 1)}
                  disabled={page >= Math.ceil(data.length / rowsPerPage) - 1}
                  sx={{ p: 0.5 }}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" color="text.secondary">
                Entries per Page
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {[10, 25, 50, 100].map((value) => (
                  <Box
                    key={value}
                    onClick={() =>
                      handleChangeRowsPerPage({ target: { value } })
                    }
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      gap: 0.5,
                    }}
                  >
                    <input
                      type="radio"
                      checked={rowsPerPage === value}
                      onChange={() => {}}
                      id={`rows-${value}`}
                    />
                    <Typography
                      component="label"
                      htmlFor={`rows-${value}`}
                      variant="body2"
                      sx={{ cursor: 'pointer' }}
                    >
                      {value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Paper>
      </>
    );
  } else {
    return <EmptyState />;
  }
};

export default UserAssignmentTable;
