import { Stack } from '@mui/material';
import { UserProvider } from 'pages/Settings/context';
import { PartnerAdd } from './PartnerAdd';
import PartnerList from './PartnerList';

export const Partners = () => {
  return (
    <UserProvider userType={'PARTNER'}>
      <Stack spacing={3}>
        <PartnerAdd />
        <PartnerList />
      </Stack>
    </UserProvider>
  );
};
