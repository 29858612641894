import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { useApi } from 'hooks';
import { getStyles } from 'pages/Module/common/styles';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { theme } from 'theme/muiTheme';
import { journeyFields } from './common/const';

export const ModuleTranslation = ({ journeyDetails, moduleId = '' }) => {
  const [sections, setSections] = useState([]);

  const [transData, setTransData] = useState([]);

  const { sectionCardStyle } = getStyles({ theme });
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useForm({});

  const {
    register: trRegister,
    watch: trWatch,
    setValue: trSetValue,
    trigger: trTrigger,
    reset: trReset,
  } = useForm({});

  const { call: getModuleById, data: moduleDetails } = useApi({
    fetcher: apis.getModuleById,
  });

  const {
    call: createJourneyModuleTranslation,
    data,
    isLoading: isTranslating,
  } = useApi({
    fetcher: apis.createJourneyModuleTranslation,
  });
  const savedTranslation = journeyDetails?.translatedModule;
  const translatedmodule = savedTranslation
    ? savedTranslation
    : data?.translatedModule;

  useEffect(() => {
    getModuleById({ moduleId });
  }, []);

  useEffect(() => {
    if (moduleDetails?.sections) {
      fillStoredData({ sections: moduleDetails?.sections });
    }
  }, [moduleDetails]);

  useEffect(() => {
    if (translatedmodule?.sections) {
      fillStoredData({
        sections: translatedmodule?.sections,
        trans: true,
      });
    }
  }, [translatedmodule]);

  const fillStoredData = ({ sections = [], trans = false }) => {
    const resetData = {};

    const processSubsections = (subSections) =>
      subSections?.map((subsection) => {
        resetData[`title-${subsection._id}`] = subsection.title;
        resetData[`content-${subsection._id}`] = subsection.content;
        resetData[`description-${subsection._id}`] = subsection.description;

        return {
          id: `${subsection._id}`,
          dbId: subsection._id,
          saved: false,
          title: subsection.title,
          content: subsection.content,
          description: subsection.description,
        };
      }) || [];

    const processedSections = sections.map((section) => {
      resetData[`sect-${section._id}`] = section.title;

      return {
        id: `sect-${section._id}`,
        dbId: section._id,
        saved: false,
        title: section.title,
        subsections: processSubsections(section.subSections),
      };
    });

    if (processedSections.length) {
      const formData = trans
        ? {
            trTitle: translatedmodule?.title,
            trCategory: translatedmodule?.category,
            trCourse: translatedmodule.course,
            trDeliveryMethod: translatedmodule.deliveryMethod,
            trDescription: translatedmodule.description,
            trStatus: translatedmodule.status,
            trStages: translatedmodule.stages,
            trSpaceOwnership: translatedmodule.spaceOwnership,
            trPlaybookType: translatedmodule.playbookType,
            trViewPlaybookList: translatedmodule.viewPlaybookList,
            trIndustryFocus: translatedmodule.industryFocus,
            trLocation: translatedmodule.location,
            trPillar: translatedmodule.pillar,
          }
        : {
            ...resetData,
            moduleName: moduleDetails.title,
            title: journeyDetails.title,
            category: journeyDetails.category,
            course: journeyDetails.course,
            deliveryMethod: journeyDetails.deliveryMethod,
            status: journeyDetails.status,
            stages: journeyDetails.stages,
            spaceOwnership: journeyDetails.spaceOwnership,
            playbookType: journeyDetails.playbookType,
            viewPlaybookList: journeyDetails.viewPlaybookList,
            industryFocus: journeyDetails.industryFocus,
            location: journeyDetails.location,
            pillar: journeyDetails.pillar,
          };

      trans ? setTransData(processedSections) : setSections(processedSections);
      trans ? trReset(formData) : reset(formData);
    }
  };

  const renderJourneyFields = ({ trans = false }) => {
    return journeyFields.map(({ name, trName, ...props }, index) => (
      <FormField
        disabled={true}
        key={`${name}-${index}`}
        loading={isTranslating}
        formType="ADD"
        field={{ name: trans ? trName : name, ...props }}
        register={trans ? trRegister : register}
        errors={errors}
        watch={trans ? trWatch : watch}
        hookFormUpdater={trans ? trSetValue : setValue}
        hookFormTrigger={trans ? trTrigger : trigger}
      />
    ));
  };

  const renderSections = (id) => {
    const infoFields = [
      {
        name: `${id}`,
        label: 'Section Title',
        type: 'text',
      },
    ];
    return infoFields.map((item) => (
      <Stack gap={2} direction={'column'} mb={3} key={item.name}>
        <FormField
          disabled
          formType="ADD"
          key={item.name}
          field={item}
          register={register}
          errors={errors}
          watch={watch}
          hookFormUpdater={setValue}
          hookFormTrigger={trigger}
        />
      </Stack>
    ));
  };

  const renderSubsections = ({ sectionId, subsectionId }) => {
    const infoFields = [
      {
        name: `title-${subsectionId}`,
        label: 'Title',
        type: 'text',
      },
      {
        name: `description-${subsectionId}`,
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      {
        name: `content-${subsectionId}`,
        label: 'Content',
        type: 'text',
        multiline: true,
      },
    ];

    return (
      <Stack gap={2} direction={'column'}>
        {infoFields.map((item) => (
          <FormField
            disabled
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
          />
        ))}
      </Stack>
    );
  };

  const getSectionCards = (sections = []) => {
    return sections && sections.length
      ? sections?.map((section, sectionIndex) => (
          <Card key={section.id} sx={sectionCardStyle}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <Typography variant="h6">Section {sectionIndex + 1}</Typography>
                <Stack direction={'row'}></Stack>
              </Stack>
            </Box>

            {renderSections(section.id)}

            {section &&
              section.length &&
              section?.subsections.map((subsection, subsectionIndex) => {
                return (
                  <Card key={subsection.id} sx={{ ...sectionCardStyle, ml: 2 }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                          Subsection {subsectionIndex + 1}
                        </Typography>
                        <Stack direction={'row'}></Stack>
                      </Stack>
                    </Box>
                    {renderSubsections({
                      sectionId: section.id,
                      subsectionId: subsection.id,
                    })}
                  </Card>
                );
              })}
          </Card>
        ))
      : null;
  };

  const getTransSectionCard = (data = []) => {
    return data.map((section, index) => {
      return (
        <Card key={section.id} sx={sectionCardStyle}>
          {isTranslating ? (
            <CircularProgress />
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={2}
                py={1.5}
              >
                <Typography variant="h6">Section {index + 1}</Typography>
                <Stack direction={'row'}></Stack>
              </Box>
              <TextField
                disabled
                size="small"
                fullWidth
                value={section.title}
                label={`Section Title`}
              />
              {section?.subsections.map((subsection, subsectionIndex) => {
                return (
                  <Card key={subsection.id} sx={{ ...sectionCardStyle, ml: 2 }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="subtitle1">
                        Subsection {subsectionIndex + 1}
                      </Typography>
                      <Stack direction={'row'}></Stack>
                    </Box>
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      value={subsection.title}
                      label={`Subsection Title`}
                    />
                  </Card>
                );
              })}
            </>
          )}
        </Card>
      );
    });
  };
  const translateFrom = (
    <Stack direction={'column'} flex={1} spacing={2}>
      <FormField
        formType="ADD"
        disabled
        field={{
          name: 'moduleName',
          label: 'Module Name',
          type: 'text',
        }}
        register={register}
        errors={errors}
        watch={watch}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />

      {getSectionCards(sections)}
      {renderJourneyFields({ trans: false })}
    </Stack>
  );
  const translateTo = (
    <Stack direction={'column'} flex={1} spacing={2}>
      <FormField
        formType="ADD"
        field={{
          name: 'targetLanguage',
          label: 'Target Language',
          type: 'select',
          options: [
            { label: 'Japanese', value: 'JPN' },
            { label: 'English', value: 'EN' },
          ],
        }}
        onChange={async (e) => {
          const language = e.target.value;
          const data = { moduleId, language };
          await createJourneyModuleTranslation({
            data,
            journeyId: journeyDetails._id,
          });
        }}
        register={register}
        errors={errors}
        watch={watch}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
      {transData.length
        ? getTransSectionCard(transData)
        : getSectionCards(sections)}
      {renderJourneyFields({ trans: true })}
    </Stack>
  );

  return (
    <Stack
      direction={'row'}
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
      sx={{ justifyContent: 'space-between' }}
    >
      {translateFrom}
      {translateTo}
    </Stack>
  );
};
