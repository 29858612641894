import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import loaderData from 'components/lottieConfig/loader.json';
import useMicDetection from 'hooks/useMicDetection';
import { useEffect } from 'react';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Caller = ({
  image,
  userName,
  designation,
  handle,
  status,
  borderColor = 'orange',
  isPersona = false,
  toggleRecording,
  playAudio,
  isRecording,
  isPlayingAudio,
  setIsMuted,
  isMuted,
  isThinking,
}) => {
  const { isSpeaking } = useMicDetection();

  useEffect(() => {
    if (typeof playAudio === 'function') {
      playAudio();
    } else {
      console.error('playAudio is not a function');
    }
  }, [playAudio]);

  const shouldPulse =
    (isSpeaking && !isPersona && isRecording) || (isPersona && isPlayingAudio);

  const shouldPersonaBorderCircleAnimate = isPersona && !isPlayingAudio;

  const shouldHumanBorderCircleAnimate = !isSpeaking && !isPersona;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '120px',
          height: '120px',
          borderRadius: '50%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          ...(shouldPulse && {
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              border: `1px solid ${borderColor}`,
              width: 'calc(100% + 10px)',
              height: 'calc(100% + 10px)',
              borderRadius: '50%',
              animation: 'pulse 1s linear infinite',
              animationPlayState: 'running',
            },
            '&::after': {
              animationDelay: '0.3s',
            },
          }),
          ...(shouldPersonaBorderCircleAnimate && {
            '&::before': {
              content: '""',
              position: 'absolute',
              width: '105px',
              height: '105px',
              borderRadius: '50%',
              background: ' url("/pattern2.png")',
              backgroundSize: 'cover, contain',
              backgroundPosition: 'center, center',
              animation:
                'border-animation 4s linear infinite, pattern-animation 10s linear infinite',
              transition: '0.5s',
            },
          }),
          ...(shouldHumanBorderCircleAnimate && {
            '&::before': {
              content: '""',
              position: 'absolute',
              width: '105px',
              height: '105px',
              borderRadius: '50%',
              background: ' url("/pattern.png")',
              backgroundSize: 'cover, contain',
              backgroundPosition: 'center, center',
              animation:
                'border-animation 4s linear infinite, pattern-animation 10s linear infinite reverse',
              transition: '0.5s',
            },
          }),
          '@keyframes pulse': {
            '0%': { transform: 'scale(0.5)', opacity: 0 },
            '50%': { transform: 'scale(1)', opacity: 1 },
            '100%': { transform: 'scale(1.3)', opacity: 0 },
          },
          '@keyframes border-animation': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },
        }}
      >
        <Avatar
          alt={userName}
          src={image}
          sx={{
            height: '100px',
            width: '100px',
            borderRadius: '50%',
            objectFit: 'cover',
            zIndex: 2,
          }}
        />
      </Box>

      <Typography
        variant="h5"
        sx={{
          color: '#00706c',
          fontFamily: 'Lexend',
          fontWeight: 300,
          fontSize: '20px',
        }}
      >
        {userName}
      </Typography>

      <Typography
        sx={{
          color: '#5b706f',
          fontFamily: 'Lexend',
          fontSize: '12px',
          fontWeight: 300,
        }}
      >
        {designation}
      </Typography>

      {handle && (
        <Typography
          sx={{
            color: '#5b706f',
            fontFamily: 'Lexend',
            fontSize: '12px',
            fontWeight: 300,
            textTransform: 'capitalize',
          }}
        >
          @ {handle}
        </Typography>
      )}

      <Typography
        sx={{
          color: '#7b8d8c',
          fontFamily: 'Lexend',
          fontSize: '12px',
          fontWeight: 300,
          lineHeight: 0,
          padding: '12px 25px',
          border: '1px solid #7b8d8c',
          borderRadius: '20px',
          mt: '10px',
          textTransform: 'capitalize',
        }}
      >
        {status}
      </Typography>

      {!isPersona ? (
        <IconButton onClick={toggleRecording} sx={{ mt: '5px' }}>
          {isRecording ? (
            <StopCircleIcon color="primary" />
          ) : (
            <PlayCircleIcon color="disabled" />
          )}
        </IconButton>
      ) : (
        <>
          {isThinking && shouldPersonaBorderCircleAnimate ? (
            <Lottie
              options={defaultOptions}
              height={40}
              width={40}
              style={{
                margin: 0,
              }}
            />
          ) : (
            <IconButton sx={{ mt: '5px' }} onClick={() => setIsMuted(!isMuted)}>
              {isMuted ? (
                <VolumeOffIcon color="disabled" />
              ) : (
                <VolumeUpIcon color="primary" />
              )}
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export default Caller;
