import { useEffect, useRef, useState } from 'react';

const useMicDetection = (idleTimeout = 45000, volumeThreshold = 30) => {
  // 60000 ms = 1 minute
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isIdle, setIsIdle] = useState(false); // Initialize as false
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const speakingTimerRef = useRef(null);
  const idleTimerRef = useRef(null);

  useEffect(() => {
    idleTimerRef.current = setTimeout(() => {
      setIsIdle(true);
    }, idleTimeout);

    const getMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioCtx = new (window.AudioContext ||
          window.webkitAudioContext)();
        const analyserNode = audioCtx.createAnalyser();

        analyserNode.fftSize = 512;
        const source = audioCtx.createMediaStreamSource(stream);
        source.connect(analyserNode);

        audioContextRef.current = audioCtx;
        analyserRef.current = analyserNode;

        detectSpeech(analyserNode);
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };

    getMicrophone();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      clearTimeout(speakingTimerRef.current);
      clearTimeout(idleTimerRef.current);
    };
  }, [idleTimeout]);

  const detectSpeech = (analyser) => {
    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const checkAudio = () => {
      analyser.getByteFrequencyData(dataArray);
      const averageVolume =
        dataArray.reduce((sum, value) => sum + value) / dataArray.length;

      if (averageVolume > volumeThreshold) {
        clearTimeout(idleTimerRef.current);
        setIsIdle(false);

        if (!isSpeaking) {
          setIsSpeaking(true);
          clearTimeout(speakingTimerRef.current);
          speakingTimerRef.current = setTimeout(() => {
            setIsSpeaking(false);
          }, 1500);
        }
        idleTimerRef.current = setTimeout(() => {
          setIsIdle(true);
        }, idleTimeout);
      } else {
        if (isSpeaking) {
          clearTimeout(speakingTimerRef.current);
          speakingTimerRef.current = setTimeout(() => {
            setIsSpeaking(false);
          }, 1500);
        }
      }

      requestAnimationFrame(checkAudio);
    };

    checkAudio();
  };

  return { isSpeaking, isIdle };
};

export default useMicDetection;
