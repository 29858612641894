import { Add } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';

const EmptyState = ({ onCreateClick }) => {
  return (
    <Box
      sx={{
        height: 600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dashed',
        borderColor: 'divider',
        borderRadius: 2,
        bgcolor: 'background.paper',
      }}
    >
      <IconButton
        onClick={onCreateClick}
        sx={{
          width: 64,
          height: 64,
          mb: 2,
          bgcolor: 'background.neutral',
          '&:hover': { bgcolor: 'background.neutral' },
        }}
      >
        <Add sx={{ width: 44, height: 44, color: 'text.secondary' }} />
      </IconButton>
      <Typography
        color="text.secondary"
        sx={{ mb: 1 }}
        fontSize={'20px'}
        fontWeight={300}
      >
        There are no assignments added at the moment.
      </Typography>
      <Button
        color="primary"
        onClick={onCreateClick}
        sx={{
          textDecoration: 'underline',
          fontSize: '20px',
          fontWeight: '300',
        }}
      >
        Click here to create a new assignment
      </Button>
    </Box>
  );
};
export default EmptyState;
