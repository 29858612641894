import { Box, Typography } from '@mui/material';

const ObjectiveScoreCard = ({ data }) => {
  if (data) {
    return (
      <Box
        sx={{
          width: '93%',
          p: 3,
          border: '1px solid #dae8e7',
          backgroundColor: '#fff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '20px',
          marginLeft: '5px',
          my: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              textWrap: 'nowrap',
            }}
          >
            {data.name}
          </Typography>
          {data && (
            <Typography
              variant="h6"
              sx={{
                color: '#243030',
                fontFamily: 'Lexend',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                textWrap: 'nowrap',
              }}
            >
              {data.score} / {data.maxScore}
            </Typography>
          )}
        </Box>
        {data && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#5b706f',
              fontFamily: 'Lexend',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 300,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  textAlign: 'justify',
                }}
              >
                {data.feedback}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
};

export default ObjectiveScoreCard;
