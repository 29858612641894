import { Edit } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { TableSkeleton } from 'components/Table';
import { DataTable } from 'components/Table/Table';
import ActionsDropdown from 'components/Table/TableActions';
import {
  useDialog,
  useTableSearch,
  useUserContext,
} from 'pages/Settings/context';
import { useEffect } from 'react';
import { colorPalette } from 'theme/colors';

import apis from 'api/api';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useDialogStore } from 'store';
import RevokeDialog from '../Coaches/components/RevokeDialog';
import { EditDetailsDialog } from './components/EditDialog';

const PartnerListTableColumns = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'status', label: 'Status' },
  { id: 'companyName', label: 'Company' },
  { id: 'companyType', label: 'Company Type' },
  { id: 'invitationDate', label: 'Invited on' },

  { id: 'actions', label: 'Actions' },
];

export const PartnerList = () => {
  const { openEditDialog, openDeleteDialog } = useDialog();
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);

  const { data, get, isFetching } = useUserContext();
  const { user: loggedUser } = useLoggedUser();
  const { openDialog } = useDialog();
  const { remove } = useUserContext();
  const searchFields = ['firstName', 'lastName', 'email', 'status'];
  const { searchTerm, setSearchTerm, filteredData } = useTableSearch({
    data,
    searchFields,
  });

  const { call: resend, isLoading: resending } = useApi({
    fetcher: (data) => {
      return apis.resendInvitation({
        organizationId: loggedUser.organization,
        invitationId: data.id,
      });
    },
    successMessage: 'Partner invitation resent successfully',
  });

  useEffect(() => {
    get();
  }, []);

  const actions = (data) => {
    return [
      ...(data.status === 'ACCEPTED'
        ? [
            {
              label: 'View/Edit Details',
              icon: <Edit color="success" />,
              onClick: () => openEditDialog({ data }),
            },
            {
              label: 'Remove Partner',
              icon: <PersonOffIcon color="error" />,
              onClick: () =>
                setDeleteDialog({
                  isOpen: true,
                  forItem: 'Partner',
                  onOk: async () => {
                    await remove({ userId: data.id, email: data.email });
                  },
                }),
            },
          ]
        : []),
      ...(data.status === 'PENDING'
        ? [
            {
              label: 'Resend Request',
              icon: <ReplayIcon color="success" />,
              onClick: () => resend(data),
            },
            {
              label: 'Revoke Request',
              icon: <HighlightOffIcon color="error" />,
              onClick: () => openDialog({ data, name: 'revoke' }),
            },
          ]
        : []),
    ];
  };

  const partnerSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        >
          <FormControlLabel value="" control={<Radio />} label="All" />
          <FormControlLabel
            value="PENDING"
            control={<Radio />}
            label="Pending"
          />
          <FormControlLabel
            value="ACCEPTED"
            control={<Radio />}
            label="Accepted"
          />
        </RadioGroup>
      </FormControl>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        padding: 2,
        borderRadius: 2,
      }}
    >
      {resending && <LoadingOverlay />}
      <Stack direction="column" spacing={5}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Partner Roster</Typography>
          {partnerSearchBar}
        </Stack>
        {isFetching ? (
          <TableSkeleton column={PartnerListTableColumns} />
        ) : (
          <DataTable
            columns={PartnerListTableColumns}
            sort={true}
            data={filteredData?.map((item) => ({
              ...item,
              actions: <ActionsDropdown actions={actions(item)} />,
              name: `${item.firstName} ${item.lastName}`,
              invitationDate: new Date(item.invitationDate).toDateString(),
              status: (
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                />
              ),
            }))}
          />
        )}
      </Stack>
      <DeleteDialog />
      <EditDetailsDialog />
      <RevokeDialog />
    </Box>
  );
};

export default PartnerList;
