import { Handshake } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BookIcon from '@mui/icons-material/Book';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SchoolIcon from '@mui/icons-material/School';
import Admin from '../Admin';
import Coaches from '../Coaches';
import Courses from '../Courses';
import { Partners } from '../Partners';
import Students from '../Students';

export const TabItems = [
  {
    label: 'Courses',
    value: 'courses',
    icon: <BookIcon />,
    content: <Courses />,
  },
  {
    label: 'Coaches',
    value: 'coaches',
    icon: <SchoolIcon />,
    content: <Coaches />,
  },
  {
    label: 'Students',
    value: 'students',
    icon: <PeopleAltIcon />,
    content: <Students />,
  },
  {
    label: 'Partners',
    value: 'partners',
    icon: <Handshake />,
    content: <Partners />,
  },
  {
    label: 'Admin',
    value: 'admin',
    icon: <AdminPanelSettingsIcon />,
    content: <Admin />,
  },
];
