export const getStyles = () => {
  const dialogWrapper = {
    width: '50vw',
    display: 'flex',
    justifyContent: 'center',
  };
  const fileInputContainer = {
    border: '2px dashed #aaa',
    borderRadius: '8px',

    width: '100%',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f9fafb',
    color: '#555',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#888',
    },
  };
  return { dialogWrapper, fileInputContainer };
};
