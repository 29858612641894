import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, Popover, TextField } from '@mui/material';
import { format } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { DateRange as DRP } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles/style.css';

export const DateRange = forwardRef(
  (
    { onDateChange, onClear, isLabel = true, width = '150px', size = 'small' },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState({
      startDate: '',
      endDate: '',
    });
    const [dateRange, setDateRange] = useState([
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ]);
    useEffect(() => {
      if (!inputValue.startDate && !inputValue.endDate) {
        const today = new Date();
        setDateRange([
          {
            startDate: today,
            endDate: today,
            key: 'selection',
          },
        ]);
      }
    }, [inputValue, onDateChange]);

    const resetDateRange = useCallback(() => {
      setInputValue({
        startDate: '',
        endDate: '',
      });

      const resetRange = [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      ];
      setDateRange(resetRange);

      if (onDateChange) {
        onDateChange({ startDate: null, endDate: null });
      }
    }, [onDateChange]);

    useImperativeHandle(ref, () => ({
      reset: resetDateRange,
    }));

    useEffect(() => {
      if (onClear) {
        onClear(resetDateRange);
      }
    }, [onClear, resetDateRange]);

    const handleSelect = (ranges) => {
      const { startDate, endDate } = ranges.selection;

      // If we're selecting the first date (startDate)
      if (
        startDate &&
        (!dateRange[0].startDate ||
          (dateRange[0].startDate && dateRange[0].endDate))
      ) {
        setInputValue({
          startDate: format(startDate, 'MM/dd/yyyy'),
          endDate: '',
        });
        setDateRange([
          {
            startDate,
            endDate: null,
            key: 'selection',
          },
        ]);
        if (onDateChange) {
          onDateChange({ startDate, endDate: null });
        }
      }
      // If we're selecting the end date
      else if (startDate && dateRange[0].startDate && endDate) {
        const newEndDate =
          endDate.getTime() !== startDate.getTime() ? endDate : null;
        setInputValue({
          startDate: format(dateRange[0].startDate, 'MM/dd/yyyy'),
          endDate: newEndDate ? format(newEndDate, 'MM/dd/yyyy') : '',
        });
        setDateRange([
          {
            startDate: dateRange[0].startDate,
            endDate: newEndDate,
            key: 'selection',
          },
        ]);
        if (onDateChange) {
          onDateChange({
            startDate: dateRange[0].startDate,
            endDate: newEndDate,
          });
        }
      }
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const clearStartDate = () => {
      const newRange = { ...dateRange[0], startDate: null };
      setInputValue((prev) => ({ ...prev, startDate: '' }));
      setDateRange([newRange]);
      if (onDateChange) {
        onDateChange(newRange);
      }
    };

    const clearEndDate = () => {
      const newRange = { ...dateRange[0], endDate: null };
      setInputValue((prev) => ({ ...prev, endDate: '' }));
      setDateRange([newRange]);
      if (onDateChange) {
        onDateChange(newRange);
      }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-range-picker-popover' : undefined;

    return (
      <Box display="flex" alignItems="center">
        <TextField
          size={size}
          label={isLabel ? 'Start Date' : null}
          placeholder={!isLabel ? 'Start Date' : ''}
          onClick={handleClick}
          value={inputValue.startDate}
          InputProps={{
            endAdornment: !inputValue.startDate ? (
              <IconButton size="small" onClick={handleClick}>
                <CalendarTodayIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={clearStartDate}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          readOnly
          sx={{ width: width }}
        />

        <Box mx={1}> - </Box>
        <TextField
          size={size}
          label={isLabel ? 'End Date' : null}
          placeholder={!isLabel ? 'End Date' : ''}
          onClick={handleClick}
          value={inputValue.endDate}
          InputProps={{
            endAdornment: !inputValue.endDate ? (
              <IconButton size="small" onClick={handleClick}>
                <CalendarTodayIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={clearEndDate}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          readOnly
          sx={{ width: width }}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DRP
            editableDateInputs={true}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={false}
            ranges={dateRange}
            locale={enUS}
            showSelectionPreview={false}
            rangeColors={['#0280609F', '#3ecf8e', '#fed14c']}
          />
        </Popover>
      </Box>
    );
  }
);

export default DateRange;
