import { FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import organizationAPI from 'api/api';
import { CustomWhiteButton } from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import { SelectCourse, SelectRegion } from 'components/SelectField';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookIcon } from 'svg/CustomSVGIcon';
import styles from './styles/Insight.module.css';

const InsightView = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [top5Students, setTop5Students] = useState([]);
  const [top5Coaches, setTop5Coaches] = useState([]);
  const [top5Questions, setTop5Questions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const hasPageRendered = useRef(true);
  const navigate = useNavigate();

  const [selectedRegionForCoach, setSelectedRegionForCoach] = useState('All');
  const [selectedCourseIdForCoach, setSelectedCourseIdForCoach] =
    useState('All');
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserDetailsNavigate = (e, active) => {
    e.preventDefault();
    navigate('/admin/insights/user-details?active=' + active);
  };

  const getStats = async (
    course = null,
    region = null,
    // dateRange = null,
    startDate = null,
    endDate = null
  ) => {
    if (startDate !== null && endDate === null) {
      return;
    }
    setLoading(true);
    try {
      const data = {};
      if (course) data.course = course;
      if (region) data.region = region;
      // if (dateRange) data.duration = dateRange;
      if (startDate && endDate) {
        data.duration = 'custom';
        data.startDate = startDate;
        data.endDate = endDate;
      }
      data.limit = '5';
      const response = await organizationAPI.getStatistics(data);

      setStats(response.data.stats);
      setTop5Students(response.data.top5Students);
      setTop5Coaches(response.data.topCoaches);
      setTop5Questions(response.data.top5Questions);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (hasPageRendered.current) {
      console.log('Initial Render');
      hasPageRendered.current = false;
      getStats(
        selectedCourseIdForCoach === 'All' ? null : selectedCourseIdForCoach,
        selectedRegionForCoach === 'All' ? null : selectedRegionForCoach,
        // selectedDateRange === 'All' ? null : selectedDateRange,
        startDate === null ? null : startDate,
        endDate === null ? null : endDate
      );
    } else if (stats.totalQuestions !== undefined) {
      console.log('Subsequent Render');
      getStats(
        selectedCourseIdForCoach === 'All' ? null : selectedCourseIdForCoach,
        selectedRegionForCoach === 'All' ? null : selectedRegionForCoach,
        // selectedDateRange === 'All' ? null : selectedDateRange,
        startDate === null ? null : startDate,
        endDate === null ? null : endDate
      );
    }
  }, [selectedRegionForCoach, selectedCourseIdForCoach, startDate, endDate]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className={styles.header}>
        <h4>Insights</h4>
      </div>

      <div className={styles.overview_filter}>
        <FormControl sx={{ width: '33%' }}>
          <SelectRegion
            value={selectedRegionForCoach}
            setValue={setSelectedRegionForCoach}
            input={'Filter by Region'}
            label={'Filter by Region'}
          />
        </FormControl>
        <FormControl sx={{ width: '33%' }}>
          <SelectCourse
            value={selectedCourseIdForCoach}
            setValue={setSelectedCourseIdForCoach}
            input={'Filter by Course'}
            label={'Filter by Course'}
          />
        </FormControl>

        <FormControl
          sx={{ width: '33%', display: 'flex', flexDirection: 'row', gap: 1 }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={'Filter by Start Date'}
              format="YYYY-MM-DD"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={'Filter by End Date'}
              format="YYYY-MM-DD"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
        </FormControl>
      </div>

      <div className={styles.overview_section}>
        <div className={styles.overview_item}>
          <h6>Total Questions Asked</h6>
          <p>{stats.totalQuestions}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Total Questions Asked to Coaches</h6>
          <p>{stats.totalCoachQuestions}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Total Questions Asked to NFTE AI</h6>
          <p>{stats.totalAIQuestions}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Total Hours Saved by NFTE AI</h6>
          <p>{stats.totalTimeSaved}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Total Questions Answered by Coaches</h6>
          <p>{stats.totalQuestionsAnsweredByCoaches}</p>
        </div>
      </div>

      <div className={styles.overview_section}>
        <div className={styles.overview_item}>
          <h6>Total Canvases in Progress</h6>
          <p>{stats.totalCanvasInProgress}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Total Canvases under Review</h6>
          <p>{stats.totalCanvasInReview}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Total Canvases Completed</h6>
          <p>{stats.totalCanvasCompleted}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Team Canvases</h6>
          <p>{stats.teamCanvases}</p>
        </div>
        <div className={styles.overview_item}>
          <h6>Personal Canvases </h6>
          <p>{stats.personalCanvases}</p>
        </div>
      </div>

      <div className={styles.overview_table_section}>
        <div className={styles.overview_table_item}>
          <div className={styles.overview_table_header}>
            <h6>Top Students</h6>
            <CustomWhiteButton
              onClick={(e) => handleUserDetailsNavigate(e, 'students')}
            >
              <BookIcon /> View All
            </CustomWhiteButton>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Count</th>
              </tr>
            </thead>

            {top5Students.map((student) => (
              <tr key={`top5Students-${student.email}`}>
                <td>{student.name}</td>
                <td>{student.email}</td>
                <td>{student.count}</td>
              </tr>
            ))}
          </table>
        </div>
        <div className={styles.overview_table_item}>
          <div className={styles.overview_table_header}>
            <h6>Top Coaches</h6>
            <CustomWhiteButton
              onClick={(e) => handleUserDetailsNavigate(e, 'coaches')}
            >
              <BookIcon /> View All
            </CustomWhiteButton>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Count</th>
              </tr>
            </thead>

            {top5Coaches.map((coach) => (
              <tr key={`top5Coaches-${coach.email}`}>
                <td>{coach.name}</td>
                <td>{coach.email}</td>
                <td>{coach.count}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <div className={styles.overview_full_table}>
        <div
          className={`${styles.overview_table_item} ${styles.overview_full_table}`}
        >
          <div className={styles.overview_table_header}>
            <h6>Top Questions</h6>
            <CustomWhiteButton
              onClick={(e) => handleUserDetailsNavigate(e, 'questions')}
            >
              <BookIcon /> View All
            </CustomWhiteButton>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Times Asked</th>
              </tr>
            </thead>

            {top5Questions.map((question, index) => (
              <tr key={`top5Questions-${index}`}>
                <td>{question.question}</td>
                <td>{question.count}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};

export default InsightView;
