import { ArrowDropDown } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { forwardRef, useMemo, useState } from 'react';

export const MultiSelect = forwardRef(
  (
    {
      creatable = false, // Determines whether to use AutoComplete
      options = [],
      selectedValues = [],
      handleSelectionChange,
      handleDelete,
      loading,
      disabled,
      error,
      errorMessage,
      label,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const dynamicOptions = useMemo(() => options, [options]);

    const handleAddOption = (newOption) => {
      const isNewOption = !dynamicOptions.find(
        (opt) => opt.value === newOption
      );
      if (isNewOption) {
        const updatedOptions = [
          ...dynamicOptions,
          { value: newOption, label: newOption },
        ];
        handleSelectionChange({
          target: { value: [...selectedValues, newOption] },
        });
      }
    };

    if (creatable) {
      return (
        <>
          <Autocomplete
            size={rest.size}
            multiple
            freeSolo
            disabled={loading || disabled}
            options={dynamicOptions}
            getOptionLabel={(option) => option.label || option}
            value={selectedValues.map(
              (val) =>
                dynamicOptions.find((opt) => opt.value === val) || {
                  value: val,
                  label: val,
                }
            )}
            onChange={(event, newValue) => {
              const updatedValues = newValue.map((item) =>
                typeof item === 'string' ? item : item.value
              );
              handleSelectionChange({ target: { value: updatedValues } });

              newValue.forEach((item) => {
                if (typeof item === 'string') handleAddOption(item);
              });
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={option.label || option}
                    {...tagProps}
                    deleteIcon={
                      <CancelIcon sx={{ ':hover': { fill: 'tomato' } }} />
                    }
                    onDelete={() => handleDelete(option.value || option)}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                placeholder="Type and enter to add..."
                error={error}
                disabled={loading || disabled}
                InputProps={{
                  ...params.InputProps,

                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      {loading ? <CircularProgress size={20} /> : null}
                      <ArrowDropDown sx={{ cursor: 'pointer' }} />
                    </>
                  ),
                }}
              />
            )}
          />
          <FormHelperText error={error}>{errorMessage}</FormHelperText>
        </>
      );
    }

    // Render Select if `creatable` is false
    return (
      <>
        {label && (
          <InputLabel size="small" id="demo-multiple-name-label">
            {label}
          </InputLabel>
        )}
        <Select
          {...rest}
          multiple
          ref={ref}
          id="demo-multiple-name-label"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          error={error}
          value={selectedValues}
          onChange={(e) => {
            handleSelectionChange(e);
            setOpen(false);
          }}
          disabled={disabled}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                const selectedOption =
                  dynamicOptions.find((option) => option.value === value) || '';
                return selectedOption ? (
                  <Chip
                    key={value}
                    label={selectedOption.label}
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={(e) => e.preventDefault()}
                    deleteIcon={
                      <CancelIcon sx={{ ':hover': { fill: 'tomato' } }} />
                    }
                    onDelete={() => handleDelete(value)}
                  />
                ) : null;
              })}
            </Box>
          )}
        >
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
              <span style={{ marginLeft: 10 }}>Loading...</span>
            </MenuItem>
          ) : (
            dynamicOptions.map(({ value, label }, index) => (
              <MenuItem key={`${value}-${index}`} value={value}>
                {label}
              </MenuItem>
            ))
          )}
        </Select>
        <FormHelperText error={error}>{errorMessage}</FormHelperText>
      </>
    );
  }
);

export default MultiSelect;
