import * as Yup from 'yup';

export const moduleInfoSchema = Yup.object().shape({
  title: Yup.string().required('Module name is required'),
});

export const moduleSectionSchema = Yup.object().shape({
  sectionTitles: Yup.object().test(
    'required-section-titles',
    'All section titles are required',
    function (value) {
      const regex = /^sectionTitle-\d+$/; // Match field names like "sectionTitle-1", "sectionTitle-2"
      const fieldErrors = Object.entries(value || {}).filter(([key, val]) => {
        if (regex.test(key)) {
          return !val || val.trim() === ''; // Check if the value is empty
        }
        return false;
      });

      return fieldErrors.length === 0; // Return true if no errors
    }
  ),
});
