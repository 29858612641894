import { Group, MoreVert, Search } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import LoadingOverlay from 'components/LoadingOverlay';
import dateFormat from 'dateformat';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useStore from 'store/useStore';

const AssignmentDetails = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const assignmentId = location.pathname.split('/').pop();
  const [data, SetData] = useState([]);
  const [userId, setUserId] = useState(null);
  const { render, setRender, clearRender } = useStore();
  const { call: convoAssignmentById, isLoading } = useApi({
    fetcher: organizationAPI.convoAssignmentById,
  });

  const { call: deleteAssigneeById } = useApi({
    fetcher: organizationAPI.deleteAssigneeById,
    successMessage: 'Assignee removed successfully',
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleMenuClick = (event, assignment) => {
    setAnchorEl(event.currentTarget);
    setSelectedAssignment(assignment);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAssignment(null);
  };

  const handleRemoveClick = () => {
    setRemoveDialogOpen(true);
    handleMenuClose();
  };

  const handleRemoveConfirm = async () => {
    const response = await deleteAssigneeById({ assignmentId, userId });
    console.log(response);
    if (!render) {
      setRender();
    }
    setRemoveDialogOpen(false);
    setSelectedAssignment(null);
    setUserId(null);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateToAssignmentView = (userId) => {
    handleMenuClose();
    navigate(`/assignment/view/${data._id}/${userId}`);
  };

  useEffect(() => {
    const fetchAssignmentById = async () => {
      const response = await convoAssignmentById(assignmentId);
      SetData(response.data);
    };
    fetchAssignmentById();
    if (render) {
      clearRender();
    }
  }, [render]);

  const renderProgressBar = (progress, total) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ flex: 1, height: 8, bgcolor: '#F4F6F8', borderRadius: 4 }}>
          <Box
            sx={{
              height: '100%',
              borderRadius: 4,
              bgcolor: '#00A76F',
              width: `${(progress / total) * 100}%`,
            }}
          />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {progress} of {total}
        </Typography>
      </Box>
    );
  };

  if (data && data.assignedTo) {
    const filteredData = data.assignedTo.filter((row) => {
      if (!searchTerm.trim()) return true;
      const searchLower = searchTerm.toLowerCase().trim();
      const userNameMatch =
        row?.userName?.toLowerCase().includes(searchLower) ?? false;
      const userIdMatch =
        row?.userId?.toLowerCase().includes(searchLower) ?? false;
      const typeMatch = row?.type?.toLowerCase().includes(searchLower) ?? false;
      const dateMatch = row?.createdAt
        ? new Date(row.createdAt)
            .toLocaleDateString()
            .toLowerCase()
            .includes(searchLower)
        : false;

      return userNameMatch || userIdMatch || typeMatch || dateMatch;
    });

    return (
      <Container maxWidth="xl" sx={{ py: 3 }}>
        {' '}
        {isLoading && <LoadingOverlay />}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
          <Typography
            variant="h4"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '28px',
              fontWeight: 300,
              letterSpacing: '-0.087px',
            }}
          >
            Assignment Details: {data.assignmentName}
          </Typography>
          <Button
            startIcon={<ArrowBackIosIcon fontSize="small" />}
            sx={{
              border: '1px solid #00706C',
              padding: '6px 16px',
              color: '#00706C',
              bgcolor: '#fff',
              '&:hover': { bgcolor: '#DFFCFBFF' },
            }}
            onClick={() => navigate('/assignments')}
          >
            Return
          </Button>
        </Box>
        <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />
        <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
          <TextField
            fullWidth
            placeholder="Search by name/keyword"
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              sx: { height: '100%' },
            }}
            sx={{
              bgcolor: 'background.paper',
              height: '50px',
              '& .MuiInputBase-root': { height: '100%' },
            }}
          />
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: 2,
            boxShadow: 'none',
            border: '1px solid #DAE8E7',
          }}
        >
          {data && data.assignedTo && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Assignee</TableCell>
                  <TableCell>Assigned on</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.userId}>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {row.userName}
                      </TableCell>
                      <TableCell>
                        {dateFormat(row.createdAt, 'mmm d, yyyy')}
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {row.type}
                      </TableCell>
                      {/* <TableCell>
                        {renderProgressBar(row.progress, row.totalScore)}
                      </TableCell> */}
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={(event) => handleMenuClick(event, row)}
                        >
                          <MoreVert />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {data && data.assignedTo && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 16px',
                backgroundColor: '#F8F9FA',
                borderRadius: '8px',
                margin: '16px 0',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Displaying {page * rowsPerPage + 1}-
                {Math.min((page + 1) * rowsPerPage, data.assignedTo.length)} of{' '}
                {data.assignedTo.length} entries
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <IconButton
                    size="small"
                    onClick={() => handleChangePage(page - 1)}
                    disabled={page === 0}
                    sx={{ p: 0.5 }}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.secondary">
                    Page {page + 1} of{' '}
                    {Math.ceil(data.assignedTo.length / rowsPerPage)}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleChangePage(page + 1)}
                    disabled={
                      page >=
                      Math.ceil(data.assignedTo.length / rowsPerPage) - 1
                    }
                    sx={{ p: 0.5 }}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Entries per Page
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {[10, 25, 50, 100].map((value) => (
                    <Box
                      key={value}
                      onClick={() =>
                        handleChangeRowsPerPage({ target: { value } })
                      }
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        gap: 0.5,
                      }}
                    >
                      <input
                        type="radio"
                        checked={rowsPerPage === value}
                        onChange={() => {}}
                        id={`rows-${value}`}
                      />
                      <Typography
                        component="label"
                        htmlFor={`rows-${value}`}
                        variant="body2"
                        sx={{ cursor: 'pointer' }}
                      >
                        {value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </TableContainer>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() =>
              handleNavigateToAssignmentView(selectedAssignment.userId)
            }
          >
            <Group sx={{ mr: 1, fontSize: 20, my: 1 }} />
            View Details
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleRemoveClick();
              setUserId(selectedAssignment.userId);
            }}
            sx={{ color: 'error.main', my: 1 }}
          >
            <Box
              component="span"
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              🚫
            </Box>
            Remove
          </MenuItem>
        </Menu>
        <Dialog
          open={removeDialogOpen}
          onClose={() => setRemoveDialogOpen(false)}
          PaperProps={{
            sx: { borderRadius: 2, maxWidth: 400, paddingY: '10px' },
          }}
        >
          <DialogTitle sx={{ textAlign: 'center', pt: 3 }}>
            <Box
              component="span"
              sx={{
                fontSize: '2rem',
                display: 'block',
                mb: 1,
              }}
            >
              <AssignmentIcon sx={{ color: '#FF0000FF', fontSize: '60px' }} />
            </Box>
            Remove Assignment
          </DialogTitle>
          <DialogContent>
            <Typography align="center" sx={{ mb: 3 }}>
              Are you sure you want to remove this assignment? This action
              cannot be undone.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={handleRemoveConfirm}
              >
                Yes
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setRemoveDialogOpen(false)}
              >
                No
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    );
  }
};

export default AssignmentDetails;
