import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SendIcon from '@mui/icons-material/Send';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { useLoggedUser } from 'contexts/UserContext';
import dateFormat from 'dateformat';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
const InfoRow = ({ label, value }) => (
  <Box
    sx={{
      display: 'flex',
      borderBottom: '1px solid #E5E5E5',
      py: 2.5,
    }}
  >
    <Typography
      sx={{
        width: '200px',
        color: '#344054',
        fontSize: '16px',
        fontWeight: 500,
      }}
    >
      {label}
    </Typography>
    <Typography
      sx={{
        color: '#667085',
        fontSize: '16px',
        textTransform: 'capitalize',
      }}
    >
      {value}
    </Typography>
  </Box>
);

const ViewConvoAssignment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const assignmentId = location.pathname.split('/').pop();
  const [data, setData] = useState(null);
  const { user } = useLoggedUser();
  const userId = user._id;
  const { setDocId } = usePersonaStore();
  const { call: getAssignmentDetails } = useApi({
    fetcher: organizationAPI.getAssignmentDetails,
    successMessage: 'Assignment details fetched successfully',
  });

  const handleRedirectToAssignmentBoard = () => {
    navigate(`/convo/assignment/board/${assignmentId}`);
  };

  useEffect(() => {
    const fetchAssignment = async () => {
      try {
        const response = await getAssignmentDetails({ userId, assignmentId });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };

    fetchAssignment();
  }, []);

  const calculateProgress = (assignedCalls) => {
    if (!assignedCalls || assignedCalls.length === 0) return 0;
    const completedCalls = assignedCalls.filter(
      (call) => call.callCompleted
    ).length;
    return Math.round((completedCalls / assignedCalls.length) * 100);
  };

  const handleRedirectToCallDetailsPage = (itemArray) => {
    if (itemArray.length > 0) {
      itemArray.forEach((item) => {
        if (item.userId === userId) {
          setDocId(item.convoTranscriptId);
          navigate(`/airoleplaycall/calldetails`);
        }
      });
    }
  };

  if (data) {
    const progress = calculateProgress(data.assignedCalls);
    const completedCalls = data.assignedCalls
      ? data.assignedCalls.filter((call) => call.callCompleted).length
      : 0;
    const totalCalls = data.assignedCalls ? data.assignedCalls.length : 0;

    const totalScore = data.assignedCalls.reduce(
      (total, call) =>
        total + Object.values(call.expectedScores).reduce((a, b) => a + b, 0),
      0
    );

    return (
      <Container maxWidth="xl" sx={{ p: 4, bgcolor: '#fff', height: '100vh' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
          <Typography
            variant="h4"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '28px',
              fontWeight: 300,
              letterSpacing: '-0.087px',
            }}
          >
            View Assignment: {data.assignmentName}
          </Typography>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Button
              startIcon={<ArrowBackIosIcon fontSize="small" />}
              sx={{
                border: '1px solid #00706C',
                padding: '6px 16px',
                color: '#00706C',
                bgcolor: '#fff',
                '&:hover': { bgcolor: '#DFFCFBFF' },
              }}
              onClick={() => navigate('/convo/assignments')}
            >
              Return
            </Button>
            <Button
              startIcon={<SendIcon />}
              sx={{
                border: '1px solid #00706C',
                padding: '6px 16px',
                color: '#fff',
                bgcolor: '#00706C',
                '&:hover': { bgcolor: '#147C79FF' },
              }}
              onClick={handleRedirectToAssignmentBoard}
            >
              Proceed To Next Call
            </Button>
          </Box>
        </Box>
        <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />

        <Paper sx={{ mb: 4, p: 3, boxShadow: 'none', border: 'none' }}>
          <Typography sx={{ fontSize: '18px', fontWeight: 500, mb: 2 }}>
            Basic Information
          </Typography>
          <Divider
            sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }}
          />
          <InfoRow label="Assignment Name" value={data.assignmentName} />
          <InfoRow label="Status" value={data.status} />
          <InfoRow
            label="Due Date"
            value={dateFormat(data.dueDate, 'mmm d, yyyy')}
          />
        </Paper>

        <Box sx={{ mb: 4, width: '98%', p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
              Assignment Progress
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <RadioButtonUncheckedIcon
                  sx={{ color: '#1A8753', fontSize: 16 }}
                />
                <Typography sx={{ color: '#667085', fontSize: '14px' }}>
                  {completedCalls} of {totalCalls}
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  width: 400,
                  height: 8,
                  borderRadius: 4,
                  bgcolor: '#F2F4F7',
                  '& .MuiLinearProgress-bar': {
                    bgcolor: '#1A8753',
                    borderRadius: 4,
                  },
                  border: '1px solid #C4CCD8FF',
                }}
              />
            </Box>
          </Box>
          <Divider sx={{ borderColor: '#E5E5E5' }} />
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={5} sx={{ borderBottom: 'none' }} />
                <TableCell
                  colSpan={4}
                  align="center"
                  sx={{
                    color: '#667085',
                    fontSize: '12px',
                    pb: 0,
                  }}
                >
                  ACHIEVED SCORES
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }} />
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Call Recipient
                </TableCell>
                <TableCell
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Mood
                </TableCell>
                <TableCell
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Tone
                </TableCell>
                <TableCell
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Difficulty
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Opener
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Discovery
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Closing
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: '#344054', fontSize: '14px', fontWeight: 500 }}
                >
                  Subtotal
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.assignedCalls.map((call, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textTransform: 'capitalize' }}>
                    {call.personaName}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {call.callCompleted ? (
                        <CheckCircleIcon
                          sx={{ color: '#00706C', fontSize: 16 }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          sx={{ color: '#DAE8E7', fontSize: 16 }}
                        />
                      )}
                      {call.callCompleted ? 'Completed' : 'Pending'}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>
                    {call.mood.name}
                  </TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>
                    {call.tone.name}
                  </TableCell>
                  <TableCell sx={{ textTransform: 'capitalize' }}>
                    {call.difficulty.name}
                  </TableCell>
                  <TableCell align="center">
                    {call.expectedScores.opener}
                  </TableCell>
                  <TableCell align="center">
                    {call.expectedScores.discovery}
                  </TableCell>
                  <TableCell align="center">
                    {call.expectedScores.closing}
                  </TableCell>
                  <TableCell align="center">
                    {Object.values(call.expectedScores).reduce(
                      (a, b) => a + b,
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {call.userIds.length > 0 ? (
                      <IconButton
                        size="small"
                        sx={{ color: '#00706C' }}
                        onClick={() =>
                          handleRedirectToCallDetailsPage(call.userIds)
                        }
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    ) : (
                      <VisibilityOffIcon sx={{ cursor: 'not-allowed' }} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    color: '#101828',
                    fontSize: '14px',
                    fontWeight: 500,
                    pr: 4,
                  }}
                >
                  TOTAL SCORE
                </TableCell>
                <TableCell colSpan={7} />

                <TableCell
                  sx={{
                    color: '#101828',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                  align="center"
                >
                  {totalScore}
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
};

export default ViewConvoAssignment;
