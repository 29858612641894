import { Box, Tab as MUITab, Tabs } from '@mui/material';
import React, { memo } from 'react';
import { colorPalette } from 'theme/colors';

const Tab = ({ tabItems, onChange }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: colorPalette.success.main }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"
          aria-label="dynamic tabs"
          variant="fullWidth"
        >
          {tabItems.map((item, index) => (
            <MUITab
              key={item.label}
              label={item.label}
              disabled={item.disabled}
              {...a11yProps(index)}
              icon={item.icon}
              iconPosition="start"
              sx={{ justifyContent: 'flex-start' }}
              onClick={(e) => {
                if (item.onClick) item.onClick(e);
              }}
            />
          ))}
        </Tabs>
      </Box>
      {tabItems.map((item, index) => (
        <CustomTabPanel key={item.label} value={value} index={index}>
          {item.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default memo(Tab);
