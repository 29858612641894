import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { Alert, Box, IconButton, keyframes } from '@mui/material';
import organizationAPI from 'api/api';
import { useApi } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px); // Optional slight slide from top
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const OpenAIStatusAlert = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const { call } = useApi({
    fetcher: organizationAPI.getOpenAiStatus,
  });

  const [lastIncident, setLastIncident] = useState(null);
  const fetchStatus = useCallback(
    async (signal) => {
      try {
        const response = await call();

        if (response.data.incident !== lastIncident) {
          setLastIncident(response.data.incident);
          if (response.data.incident === 'major') {
            setShow(true);
            setMessage(response.data.message);
          } else {
            setShow(false);
          }
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching OpenAI status:', error);
        }
      }
    },
    [lastIncident]
  );

  const memoizedFetchStatus = useMemo(() => {
    let timeoutId;
    return () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const recursiveFetch = async () => {
        await fetchStatus(signal);
        timeoutId = setTimeout(recursiveFetch, 30000);
      };

      recursiveFetch();

      return () => {
        clearTimeout(timeoutId);
        controller.abort();
      };
    };
  }, [fetchStatus]);

  useEffect(() => {
    const cleanup = memoizedFetchStatus();
    return cleanup;
  }, [memoizedFetchStatus]);

  if (!show) return null;

  return (
    <Box>
      <Alert
        severity="error"
        icon={<InfoIcon sx={{ color: 'white' }} />}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setShow(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        }
        sx={{
          animation: `${fadeIn} 1s ease-in-out`,
          borderRadius: '8px',
          backgroundColor: '#dc3545',
          color: 'white',
          '& .MuiAlert-message': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          '& .MuiAlert-icon': {
            color: 'white',
            marginRight: 1,
          },
        }}
      >
        Server is currently under maintenance. {message}
      </Alert>
    </Box>
  );
};

export default OpenAIStatusAlert;
