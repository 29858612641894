import { yupResolver } from '@hookform/resolvers/yup';
import { ExitToApp } from '@mui/icons-material';
import { Button, Divider, Stack, Typography } from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import { UserProvider } from 'pages/Settings/context';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useItemStore } from 'store';
import { cleanObject, StoreKeys } from 'utils';
import { journeyDetails } from './common/schema';
import { JourneyDetails } from './JourneyDetails';
import { ModuleSelection } from './ModuleSelection';
import { ModuleTranslation } from './ModuleTranslation';

export const AddJourney = () => {
  const navigate = useNavigate();

  const itemStore = useItemStore((state) => state.items);
  const updateItemStore = useItemStore((state) => state.updateItem);
  const [currentJourney, setCurrentJourney] = useState(null);
  const [showPage, setShowPage] = useState('details');
  const [visitedPages, setVisitedPages] = useState({
    details: false,
    module: false,
    aiRecom: false,
    translation: false,
  });

  const updateVisitedPages = (page) => {
    setVisitedPages((prev) => ({ ...prev, [page]: true }));
  };

  const { call: createJourney, isLoading: isJourneyCreating } = useApi({
    fetcher: apis.createJourney,
    successMessage: 'Journey created successfully',
  });

  const { call: createJourneyModules, data: aiGenModules } = useApi({
    fetcher: apis.createJourneyModules,
  });

  const methods = useForm({
    resolver: yupResolver(journeyDetails),
    mode: 'onChange',
  });

  const saveJourneyDetails = async (data) => {
    const cleanData = cleanObject(data);
    const response = await createJourney({
      data: cleanData,
    });

    setCurrentJourney(response.data);
    const updatedList = [...itemStore[StoreKeys.JOURNEYS], response.data];
    updateItemStore({ key: StoreKeys.JOURNEYS, value: updatedList });

    return response;
  };

  const saveAndExit = async (data) => {
    await saveJourneyDetails(data);
    navigate(-1);
  };

  useEffect(() => {
    methods.reset();
  }, []);

  const pageNavigation = {
    details: {
      component: <JourneyDetails />,
      prevLabel: 'Back: Journey List', // Disabled
      nextLabel: 'Next: Modules',
      onPrev: () => navigate('/journey'),
      onNext: async (data) => {
        await saveJourneyDetails(data);

        setShowPage('module');
        updateVisitedPages('module');
        updateVisitedPages('details');
      },
    },
    module: {
      component: <ModuleSelection />,
      prevLabel: 'Back: Details',
      nextLabel: 'Next: AI Recommendation',
      onPrev: () => setShowPage('details'),
      onNext: async () => {
        const selectedModules = itemStore['selectedModules'];

        await createJourneyModules({
          modules: selectedModules,
          journeyId: currentJourney._id,
        });

        setShowPage('aiRecom');
        updateVisitedPages('aiRecom');
      },
    },
    aiRecom: {
      component: <ModuleSelection modules={[aiGenModules]} />,
      prevLabel: 'Back: Modules',
      nextLabel: 'Next: Translation',
      onPrev: () => setShowPage('module'),
      onNext: () => {
        setShowPage('translation');
        updateVisitedPages('translation');
      },
    },
    translation: {
      component: (
        <ModuleTranslation
          journeyDetails={currentJourney}
          moduleId={aiGenModules?.aiModuleId}
        />
      ),
      prevLabel: 'Back: AI Recommendation',
      nextLabel: 'Finalize',
      onPrev: () => setShowPage('aiRecom'),
      onNext: () => navigate(-1),
    },
  };

  const Header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Journey Builder</Typography>
      <Stack direction={'row'} spacing={2}>
        <Button
          variant="outlined"
          onClick={pageNavigation[showPage].onPrev}
          disabled={showPage === 'module' && visitedPages['details']}
        >
          {pageNavigation[showPage].prevLabel}
        </Button>
        <Button
          variant="outlined"
          disabled={showPage === 'section'}
          startIcon={<ExitToApp />}
          onClick={methods.handleSubmit(saveAndExit)}
        >
          Save and Exit
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            const onNext = pageNavigation[showPage].onNext;
            if (showPage === 'details') {
              methods.handleSubmit(onNext)();
            } else {
              onNext();
            }
          }}
        >
          {pageNavigation[showPage].nextLabel}
        </Button>
      </Stack>
    </Stack>
  );
  return (
    <UserProvider userType={'PARTNER'}>
      <FormProvider {...methods}>
        <ContentBox>
          {isJourneyCreating ? <LoadingOverlay /> : null}
          <Stack direction={'column'} gap={5}>
            {Header}
            <Divider />
            {pageNavigation[showPage].component}
          </Stack>
        </ContentBox>
      </FormProvider>
    </UserProvider>
  );
};
