import { CircularProgress, Divider, Typography } from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import { DataTable } from 'components/Table';
import { useApi } from 'hooks';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAuthStore } from 'store';
import { useDialogStore, useItemStore } from 'store/useStore';
import { StoreKeys } from 'utils';

const SectionListTableColumns = [
  { id: 'title', label: 'Section' },
  { id: 'activity', label: 'Activity' },
  { id: 'minutes', label: 'Minutes' },
];

export const ModuleDetails = () => {
  const editDialogState = useDialogStore((state) => state.editDialog);
  const user = useAuthStore((state) => state.loggedUser);
  const [sectionData, setSectionData] = useState([]);
  const itemStore = useItemStore((state) => state.items);

  const { call: fetchModules, isLoading: isFetchingModules } = useApi({
    fetcher: () => {
      return apis.getModuleList({ organizationId: user.organization });
    },
    onSuccess: (modules) => {
      generateSectionData(modules);
    },
  });

  const generateSectionData = (modules) => {
    const dataTableRows = modules.flatMap((item) =>
      item.sections.map((section) => ({
        title: section.title,
        activity: section.activity,
        minutes: section.minutes,
      }))
    );
    setSectionData(dataTableRows);
  };

  useEffect(() => {
    const modulesInGlobalStore = itemStore[StoreKeys.MODULES];
    if (!modulesInGlobalStore) {
      fetchModules();
    } else {
      generateSectionData(itemStore[StoreKeys.MODULES]);
    }
  }, [itemStore[StoreKeys.MODULES]]);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useFormContext();

  useEffect(() => {
    reset(editDialogState.data);
  }, [editDialogState.data]);

  const renderFormFields = () => {
    const infoFields = [
      { name: 'title', label: 'Module Title', type: 'text' },
      { name: 'category', label: 'Category', type: 'text' },
      { name: 'playbookType', label: 'Playbook Type', type: 'text' },
      {
        name: 'viewPlaybookList',
        label: 'Ventures Who Can See This Playbook',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      { name: 'documentTemplate', label: 'Document Template', type: 'text' },
      {
        name: 'spaceOwnership',
        label: 'Space Ownership',
        type: 'text',
      },
    ];

    const settingsFields = [
      {
        name: 'course',
        label: 'Course',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'deliveryMethod',
        label: 'Delivery Method',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'industryFocus',
        label: 'Industry Focus',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'location',
        label: 'Location',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'pillar',
        label: 'Pillar',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'stages',
        label: 'Stage of Business',
        type: 'multiSelect',
        autoComplete: true,
      },
    ];

    return (
      <>
        {/* <AvatarSelector title={'Module Cover'} /> */}
        {infoFields.map((item) => (
          <FormField
            //loading={srLoading || personasLoading}
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
          />
        ))}
        <Typography variant="button">Module Settings</Typography>
        <Divider sx={{ borderColor: 'primary.main' }} />
        {settingsFields.map((item) => (
          <FormField
            //loading={srLoading || personasLoading}
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
          />
        ))}
      </>
    );
  };

  const shouldShowSectionOverview = true;
  const sectionOverviewLoading = sectionData.length ? false : isFetchingModules;
  return (
    <ContentBox
      id="temp"
      sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
    >
      {renderFormFields()}
      {shouldShowSectionOverview ? (
        <>
          <Divider />
          <Typography variant="button">Section Overview</Typography>
          {sectionOverviewLoading ? (
            <CircularProgress />
          ) : (
            <DataTable columns={SectionListTableColumns} data={sectionData} />
          )}
        </>
      ) : null}
    </ContentBox>
  );
};
