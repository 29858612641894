import { yupResolver } from '@hookform/resolvers/yup';
import {
  Assistant,
  Checklist,
  SpaceDashboard,
  Translate,
} from '@mui/icons-material';
import apis from 'api/api';
import EditDialogWrapper from 'components/Dialog/DialogWrapper';
import { Tab } from 'components/Tab';
import { useApi } from 'hooks';
import { UserProvider } from 'pages/Settings/context';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDialogStore, useItemStore } from 'store';
import { cleanObject, StoreKeys } from 'utils';
import { JourneyDetails } from '../JourneyDetails';
import { ModuleSelection } from '../ModuleSelection';
import { ModuleTranslation } from '../ModuleTranslation';
import { journeyDetails } from './schema';

export const EditDialog = ({ forItem = '' }) => {
  const [aiModules, setAIModules] = useState([]);
  const updateEditDialogState = useDialogStore((state) => state.setEditDialog);
  const dialogState = useDialogStore((state) => state.editDialog);
  const journeyId = dialogState?.data?._id;

  const itemStore = useItemStore((state) => state.items);

  const updateItemStore = useItemStore((state) => state.updateItem);
  const deleteItemStore = useItemStore((state) => state.deleteItem);

  const methods = useForm({
    resolver: yupResolver(journeyDetails),
    mode: 'onChange',
  });

  const {
    call: getJourneyDetails,
    data: journeyInfo,
    isLoading: journeyIsLoading,
  } = useApi({
    fetcher: apis.getJourney,
  });

  const { call: updateJourney } = useApi({
    fetcher: apis.updateJourney,
    successMessage: 'Journey updated successfully',
  });

  const { call: createJourneyModules, data: aiGenModules } = useApi({
    fetcher: apis.createJourneyModules,
    onSuccess: (res) => {
      setAIModules([{ moduleId: res?.aiModuleId }]);
    },
  });

  useEffect(() => {
    if (journeyInfo?.aiModuleId) {
      setAIModules([{ moduleId: journeyInfo?.aiModuleId }]);
    }
  }, [journeyInfo]);

  const handleEdit = (value) => {
    const cleanData = cleanObject(value);
    const keysToRemove = [
      'organizationId',
      'userId',
      'createdAt',
      'sections',
      'modules',
      'aiModuleId',
      'targetLanguage',
      'translatedModule',
      '__v',
    ];

    const dataToUpdate = Object.fromEntries(
      Object.entries(cleanData).filter(([key]) => !keysToRemove.includes(key))
    );

    updateJourney({
      data: dataToUpdate,
      journeyId: cleanData._id,
    });

    const updatedList = itemStore[StoreKeys.JOURNEYS].map((journey) =>
      journey._id === cleanData._id ? { ...journey, ...cleanData } : journey
    );

    updateItemStore({ key: StoreKeys.JOURNEYS, value: updatedList });

    updateEditDialogState({ data: cleanData });
    methods.reset();
  };

  const areModuleArraysEqual = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((module1) => {
      const matchingModule = arr2.find(
        (module2) => module2.moduleId === module1.moduleId
      );
      if (!matchingModule) {
        return false;
      }

      const sections1 = new Set(module1.sections);
      const sections2 = new Set(matchingModule.sections);

      if (sections1.size !== sections2.size) {
        return false;
      }

      return [...sections1].every((section) => sections2.has(section));
    });
  };

  const handleClickModuleRecom = async () => {
    const currentSelectedModules = useItemStore.getState().items;

    const isModulesIdentical = areModuleArraysEqual(
      await currentSelectedModules?.selectedModules,
      await journeyInfo.modules
    );

    if (!isModulesIdentical) {
      await createJourneyModules({
        modules: currentSelectedModules?.selectedModules,
        journeyId: journeyInfo._id,
      });
    }
  };

  const TabItems = useMemo(
    () => [
      {
        label: 'Journey Details',
        value: 'journey_details',
        icon: <Checklist />,
        content: <JourneyDetails />,
      },
      {
        label: 'Module Selection',
        value: 'module_selection',
        icon: <SpaceDashboard />,
        content: <ModuleSelection selected={journeyInfo?.modules} />,
        onClick: () => {
          if (journeyId) {
            getJourneyDetails({ journeyId });
          }
        },
      },
      {
        label: 'Recommendation',
        value: 'module_recommendation',
        icon: <Assistant />,
        content: <ModuleSelection aiRecommended={true} selected={aiModules} />,
        onClick: () => handleClickModuleRecom(),
      },
      {
        label: 'Translation',
        value: 'module_translation',
        icon: <Translate />,
        content: (
          <ModuleTranslation
            journeyDetails={journeyInfo}
            moduleId={aiModules[0]?.moduleId || ''}
          />
        ),
      },
    ],
    [journeyId, journeyInfo, aiModules]
  );

  return (
    <UserProvider userType={'PARTNER'}>
      <FormProvider {...methods}>
        <EditDialogWrapper
          forItem={forItem}
          onSubmit={methods.handleSubmit(handleEdit)}
          sx={{ width: '75vw' }}
          onClose={() => {
            methods.reset();
            deleteItemStore({ key: 'selectedModules' });
          }}
        >
          <Tab tabItems={TabItems} />
        </EditDialogWrapper>
      </FormProvider>
    </UserProvider>
  );
};
