import { Check, Clear, Close, Edit, Remove, Search } from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useAuthStore } from 'store';
import useStore from 'store/useStore';

const statuses = ['assigned', 'active', 'completed', 'overdue', 'archived'];

const initialEntry = {
  persona: null,
  mood: null,
  tone: null,
  difficulty: null,
  expectedScores: { opener: '', discovery: '', closing: '' },
};

const EditAssignment = ({ onClose, id }) => {
  const [assignmentName, setAssignmentName] = useState('');
  const [status, setStatus] = useState('active');
  const [dueDate, setDueDate] = useState('');
  const [assignees, setAssignees] = useState([]);
  const [descriptionPrompt, setDescriptionPrompt] = useState('');
  const [users, setUsers] = useState([]);
  const user = useAuthStore((state) => state.loggedUser);
  const [personas, setPersonas] = useState([]);
  const [behaviors, setBehaviors] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedEntry, setEditedEntry] = useState(initialEntry);
  const [newEntry, setNewEntry] = useState({ ...initialEntry });
  const [allRecommendations, setAllRecommendations] = useState([]);
  const { render, setRender } = useStore();
  const { call: convoAssignmentById } = useApi({
    fetcher: organizationAPI.convoAssignmentById,
  });

  const { call: getUserByRole } = useApi({
    fetcher: organizationAPI.getUserByRole,
  });

  const { call: generateSuggestedPersona, isLoading } = useApi({
    fetcher: organizationAPI.generateSuggestedPersona,
    successMessage: 'Suggested Persona Generated successfully',
  });

  const { call: getPersonas } = useApi({
    fetcher: organizationAPI.getAllPersonas,
  });

  const { call: getBehaviors } = useApi({
    fetcher: organizationAPI.getAIBehaviors,
  });

  const { call: updateConvoAssignment } = useApi({
    fetcher: organizationAPI.updateConvoAssignmentById,
    successMessage: 'Assignment Updated successfully',
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await convoAssignmentById(id);

      setAssignmentName(response.data.assignmentName || '');
      setStatus(response.data.status || 'active');
      setDueDate(
        response.data.dueDate ? formatDate(response.data.dueDate) : ''
      );
      setAssignees(response.data.assignedTo || []);
      setDescriptionPrompt(response.data.description || '');
      setAllRecommendations(response.data.assignedCalls || []);
    };

    const fetchUser = async () => {
      const response = await getUserByRole({
        orgId: user.organization,
        role: 'SALES_REP',
      });
      setUsers(response.data);
    };

    const fetchPersonas = async () => {
      const response = await getPersonas();
      setPersonas(response.data);
    };

    const fetchBehaviors = async () => {
      const response = await getBehaviors();
      setBehaviors(response.data);
    };

    fetchData();
    fetchUser();
    fetchPersonas();
    fetchBehaviors();
  }, [id]);

  const handleEditClick = (index, row) => {
    setEditingIndex(index);
    setEditedEntry({
      persona: personas.find((p) => p._id === row.personaId) || {
        _id: row.personaId,
        name: row.personaName,
      },
      mood: behaviors?.mood?.find((m) => m.id === row.mood.id) || {
        id: row.mood.id,
        name: row.mood.name,
      },
      tone: behaviors?.tone?.find((t) => t.id === row.tone.id) || {
        id: row.tone.id,
        name: row.tone.name,
      },
      difficulty: behaviors?.difficulty?.find(
        (d) => d.id === row.difficulty.id
      ) || {
        id: row.difficulty.id,
        name: row.difficulty.name,
      },
      expectedScores: { ...row.expectedScores },
    });
  };

  const handleEditRecommendation = (index, row) => {
    setEditingIndex(index);
    setEditedEntry({
      persona: personas.find((p) => p._id === row.personaId) || {
        _id: row.personaId,
        name: row.personaName,
      },
      mood: behaviors?.mood?.find((m) => m.id === row.mood.id) || {
        id: row.mood.id,
        name: row.mood.name,
      },
      tone: behaviors?.tone?.find((t) => t.id === row.tone.id) || {
        id: row.tone.id,
        name: row.tone.name,
      },
      difficulty: behaviors?.difficulty?.find(
        (d) => d.id === row.difficulty.id
      ) || {
        id: row.difficulty.id,
        name: row.difficulty.name,
      },
      expectedScores: { ...row.expectedScores },
    });
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditedEntry(initialEntry);
  };

  const handlegenerateSuggestedPersona = async () => {
    const response = await generateSuggestedPersona({
      description: descriptionPrompt,
    });
    setAllRecommendations([...response.data]);
  };

  const handleDeleteRecommendation = (index) => {
    const updatedRecommendations = allRecommendations.filter(
      (_, i) => i !== index
    );
    setAllRecommendations(updatedRecommendations);
  };

  const handleAddNewEntry = () => {
    if (
      newEntry.persona &&
      newEntry.mood &&
      newEntry.tone &&
      newEntry.difficulty &&
      newEntry.expectedScores.opener &&
      newEntry.expectedScores.discovery &&
      newEntry.expectedScores.closing
    ) {
      const newRecommendation = {
        personaId: newEntry.persona._id,
        personaName: newEntry.persona.name,
        mood: { id: newEntry.mood.id, name: newEntry.mood.name },
        tone: { id: newEntry.tone.id, name: newEntry.tone.name },
        difficulty: {
          id: newEntry.difficulty.id,
          name: newEntry.difficulty.name,
        },
        expectedScores: {
          opener: parseInt(newEntry.expectedScores.opener, 10),
          discovery: parseInt(newEntry.expectedScores.discovery, 10),
          closing: parseInt(newEntry.expectedScores.closing, 10),
        },
      };

      setAllRecommendations([...allRecommendations, newRecommendation]);
      setNewEntry(initialEntry);
    }
  };

  const handleRemoveEntry = (index) => {
    const updatedRecommendations = allRecommendations.filter(
      (_, i) => i !== index
    );
    setAllRecommendations(updatedRecommendations);
  };

  const handleSaveEdit = () => {
    if (editedEntry && editingIndex !== null) {
      const updatedRecommendation = {
        personaId: editedEntry.persona ? editedEntry.persona._id : '',
        personaName: editedEntry.persona ? editedEntry.persona.name : '',
        mood: editedEntry.mood
          ? { id: editedEntry.mood.id, name: editedEntry.mood.name }
          : null,
        tone: editedEntry.tone
          ? { id: editedEntry.tone.id, name: editedEntry.tone.name }
          : null,
        difficulty: editedEntry.difficulty
          ? { id: editedEntry.difficulty.id, name: editedEntry.difficulty.name }
          : null,
        expectedScores: editedEntry.expectedScores,
      };

      const updatedRecommendations = [...allRecommendations];
      updatedRecommendations[editingIndex] = updatedRecommendation;
      setAllRecommendations(updatedRecommendations);
      setEditingIndex(null);
      setEditedEntry(initialEntry);
    }
  };
  const formatDate = (isoDate) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async () => {
    const formattedData = {
      assignmentName: assignmentName,
      description: descriptionPrompt,
      assignedTo: assignees.map((assignee) => ({
        userId: assignee.userId || assignee.id,
        userName:
          assignee.userName || `${assignee.firstName} ${assignee.lastName}`,
        type: assignee.type || 'user',
      })),
      assignedCalls: allRecommendations.map((recommendation) => ({
        personaId: recommendation.personaId,
        personaName: recommendation.personaName,
        mood: { id: recommendation.mood.id, name: recommendation.mood.name },
        tone: { id: recommendation.tone.id, name: recommendation.tone.name },
        difficulty: {
          id: recommendation.difficulty.id,
          name: recommendation.difficulty.name,
        },
        expectedScores: recommendation.expectedScores,
      })),
      dueDate: dueDate ? formatDate(dueDate, 'yyyy-MM-dd') : null,
      status: status,
    };

    await updateConvoAssignment({
      data: formattedData,
      assignmentId: id,
    });

    if (!render) {
      setRender();
    }
    onClose();
  };

  const isFormValid = () => {
    return (
      assignmentName &&
      descriptionPrompt &&
      assignees.length > 0 &&
      allRecommendations.length > 0 &&
      dueDate &&
      status
    );
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: 300 }}>
          Edit Assignment
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={onClose}
            startIcon={<Close />}
            sx={{ height: '40px' }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            sx={{ height: '40px', width: 100 }}
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            Update
          </Button>
        </Stack>
      </Box>

      <Box sx={{ p: 3, flex: 1, overflow: 'auto' }}>
        <Typography
          variant="h6"
          sx={{ mb: 3, fontSize: '20px', fontWeight: 300 }}
        >
          Assignment Info
        </Typography>

        <Stack spacing={3}>
          <TextField
            fullWidth
            label="ASSIGNMENT NAME"
            placeholder="Alpha Omega"
            value={assignmentName}
            onChange={(e) => setAssignmentName(e.target.value)}
          />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              select
              fullWidth
              label="STATUS"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              sx={{ textTransform: 'capitalize' }}
            >
              {statuses.map((statusOption) => (
                <MenuItem
                  key={statusOption}
                  value={statusOption}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {statusOption}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              label="DUE DATE"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </Box>

          <Box>
            {users && (
              <Autocomplete
                multiple
                options={users}
                getOptionLabel={(option) =>
                  `${option.firstName || ''} ${option.lastName || ''}`
                }
                value={assignees}
                onChange={(event, newValue) => {
                  setAssignees(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ASSIGNED TO"
                    placeholder="Search by name of individual or team"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props}>
                    <Avatar
                      src={option.avatar ? option.avatar : './user.jpg'}
                      alt={option.name}
                      sx={{ width: 24, height: 24, mr: 1 }}
                    >
                      {option.firstName + ' ' + option.lastName}
                    </Avatar>
                    {option.firstName + ' ' + option.lastName}
                  </MenuItem>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      avatar={
                        <Avatar
                          src={option.avatar ? option.avatar : './user.jpg'}
                          alt={option.firstName + ' ' + option.lastName}
                        >
                          {option.firstName + ' ' + option.lastName}
                        </Avatar>
                      }
                      label={
                        option.userName
                          ? option.userName
                          : option.firstName + ' ' + option.lastName
                      }
                      {...getTagProps({ index })}
                      sx={{ m: 0.5 }}
                    />
                  ))
                }
              />
            )}
          </Box>

          <Box>
            <TextField
              fullWidth
              label="DESCRIPTION PROMPT"
              multiline
              rows={4}
              placeholder="Enter a description of the assignment which would serve as a prompt for generating recommendations"
              value={descriptionPrompt}
              onChange={(e) => setDescriptionPrompt(e.target.value)}
            />
            <Box display={'flex'} gap={2} alignItems={'center'} sx={{ mt: 2 }}>
              <Button
                fullWidth
                variant="contained"
                sx={{ height: '44px' }}
                startIcon={
                  <ReplayIcon
                    sx={{
                      animation: isLoading
                        ? 'reverseSpin 1s linear infinite'
                        : 'none',
                      '@keyframes reverseSpin': {
                        '0%': {
                          transform: 'rotate(360deg)',
                        },
                        '100%': {
                          transform: 'rotate(0deg)',
                        },
                      },
                    }}
                  />
                }
                onClick={() => handlegenerateSuggestedPersona()}
                disabled={descriptionPrompt.length <= 5}
              >
                {allRecommendations.length > 0
                  ? 'Regenerate Recommendations'
                  : 'Generate Recommendations'}
              </Button>
              {/* <Button
                fullWidth
                sx={{
                  border: '1px solid #6F7575FF',
                  color: '#6F7575FF',
                  height: '46px',
                  '&:hover': {
                    backgroundColor: '#C3C9C9FF',
                  },
                }}
                startIcon={<UndoIcon />}
              >
                Revert to Previous Generation
              </Button> */}
            </Box>
          </Box>
          {allRecommendations.length > 0 && (
            <Box sx={{ my: 3, pb: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ border: 'none', width: '14.28%' }} />
                      <TableCell sx={{ border: 'none', width: '14.28%' }} />
                      <TableCell sx={{ border: 'none', width: '14.28%' }} />
                      <TableCell sx={{ border: 'none', width: '14.28%' }} />
                      <TableCell colSpan={3} align="center">
                        EXPECTED SCORES
                      </TableCell>
                      <TableCell sx={{ border: 'none', width: '14.28%' }} />
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" sx={{ width: '14.28%' }}>
                        Persona
                      </TableCell>
                      <TableCell align="center" sx={{ width: '14.28%' }}>
                        Mood
                      </TableCell>
                      <TableCell align="center" sx={{ width: '14.28%' }}>
                        Tone
                      </TableCell>
                      <TableCell align="center" sx={{ width: '14.28%' }}>
                        Difficulty
                      </TableCell>
                      <TableCell align="center" sx={{ width: '14.28%' }}>
                        Opener
                      </TableCell>
                      <TableCell align="center" sx={{ width: '14.28%' }}>
                        Discovery
                      </TableCell>
                      <TableCell align="center" sx={{ width: '14.28%' }}>
                        Closing
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                          width: '14.28%',
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allRecommendations.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? '#f5f5f5' : 'white',
                        }}
                      >
                        <TableCell sx={{ width: '14.28%' }}>
                          {editingIndex === index ? (
                            <Select
                              fullWidth
                              size="small"
                              value={editedEntry.persona?._id || row.personaId}
                              onChange={(e) => {
                                const selectedPersona = personas.find(
                                  (p) => p._id === e.target.value
                                ) || {
                                  _id: row.personaId,
                                  name: row.personaName,
                                };

                                setEditedEntry({
                                  ...editedEntry,
                                  persona: selectedPersona,
                                });
                              }}
                            >
                              <MenuItem value={row.personaId}>
                                {row.personaName}
                              </MenuItem>
                              {personas.map((persona) => (
                                <MenuItem key={persona._id} value={persona._id}>
                                  {persona.name}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            row.personaName
                          )}
                        </TableCell>
                        {behaviors && (
                          <TableCell sx={{ width: '14.28%' }}>
                            {editingIndex === index ? (
                              <Select
                                fullWidth
                                size="small"
                                value={editedEntry.mood?.id || row.mood.id}
                                onChange={(e) => {
                                  const selectedMood = behaviors.mood.find(
                                    (m) => m.id === e.target.value
                                  ) || {
                                    id: row.mood.id,
                                    name: row.mood.name,
                                  };

                                  setEditedEntry({
                                    ...editedEntry,
                                    mood: selectedMood,
                                  });
                                }}
                              >
                                <MenuItem value={row.mood.id}>
                                  {row.mood.name}
                                </MenuItem>
                                {behaviors?.mood?.map((mood) => (
                                  <MenuItem key={mood.id} value={mood.id}>
                                    {mood.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              row.mood.name
                            )}
                          </TableCell>
                        )}
                        {behaviors && (
                          <TableCell sx={{ width: '14.28%' }}>
                            {editingIndex === index ? (
                              <Select
                                fullWidth
                                size="small"
                                value={editedEntry.tone?.id || row.tone.id}
                                onChange={(e) => {
                                  const selectedTone = behaviors.tone.find(
                                    (t) => t.id === e.target.value
                                  ) || {
                                    id: row.tone.id,
                                    name: row.tone.name,
                                  };
                                  setEditedEntry({
                                    ...editedEntry,
                                    tone: selectedTone,
                                  });
                                }}
                              >
                                <MenuItem value={row.tone.id}>
                                  {row.tone.name}
                                </MenuItem>
                                {behaviors?.tone?.map((tone) => (
                                  <MenuItem key={tone.id} value={tone.id}>
                                    {tone.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              row.tone.name
                            )}
                          </TableCell>
                        )}
                        {behaviors && (
                          <TableCell sx={{ width: '14.28%' }}>
                            {editingIndex === index ? (
                              <Select
                                fullWidth
                                size="small"
                                value={
                                  editedEntry.difficulty?.id ||
                                  row.difficulty.id
                                }
                                onChange={(e) => {
                                  const selectedDifficulty =
                                    behaviors.difficulty.find(
                                      (d) => d.id === e.target.value
                                    ) || {
                                      id: row.difficulty.id,
                                      name: row.difficulty.name,
                                    };
                                  setEditedEntry({
                                    ...editedEntry,
                                    difficulty: selectedDifficulty,
                                  });
                                }}
                              >
                                <MenuItem value={row.difficulty.id}>
                                  {row.difficulty.name}
                                </MenuItem>
                                {behaviors?.difficulty?.map((difficulty) => (
                                  <MenuItem
                                    key={difficulty.id}
                                    value={difficulty.id}
                                  >
                                    {difficulty.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              row.difficulty.name
                            )}
                          </TableCell>
                        )}

                        <TableCell align="center" sx={{ width: '14.28%' }}>
                          {editingIndex === index ? (
                            <TextField
                              fullWidth
                              size="small"
                              sx={{ textAlign: 'center' }}
                              value={
                                editedEntry.expectedScores.opener ||
                                row.expectedScores.opener
                              }
                              onChange={(e) => {
                                const rawValue = e.target.value;
                                const value =
                                  rawValue === ''
                                    ? ''
                                    : Math.max(
                                        1,
                                        Math.min(5, Number(rawValue))
                                      );
                                setEditedEntry({
                                  ...editedEntry,
                                  expectedScores: {
                                    ...editedEntry.expectedScores,
                                    opener: value,
                                  },
                                });
                              }}
                              type="number"
                              inputProps={{ min: 1, max: 5 }}
                            />
                          ) : (
                            row.expectedScores.opener
                          )}
                        </TableCell>
                        <TableCell align="center" sx={{ width: '14.28%' }}>
                          {editingIndex === index ? (
                            <TextField
                              fullWidth
                              size="small"
                              sx={{ textAlign: 'center' }}
                              value={
                                editedEntry.expectedScores.discovery ||
                                row.expectedScores.discovery
                              }
                              onChange={(e) => {
                                const rawValue = e.target.value;
                                const value =
                                  rawValue === ''
                                    ? ''
                                    : Math.max(
                                        1,
                                        Math.min(5, Number(rawValue))
                                      );
                                setEditedEntry({
                                  ...editedEntry,
                                  expectedScores: {
                                    ...editedEntry.expectedScores,
                                    discovery: value,
                                  },
                                });
                              }}
                              type="number"
                              inputProps={{ min: 1, max: 5 }}
                            />
                          ) : (
                            row.expectedScores.discovery
                          )}
                        </TableCell>
                        <TableCell align="center" sx={{ width: '14.28%' }}>
                          {editingIndex === index ? (
                            <TextField
                              fullWidth
                              size="small"
                              sx={{ textAlign: 'center' }}
                              value={
                                editedEntry.expectedScores.closing ||
                                row.expectedScores.closing
                              }
                              onChange={(e) => {
                                const rawValue = e.target.value;
                                const value =
                                  rawValue === ''
                                    ? ''
                                    : Math.max(
                                        1,
                                        Math.min(5, Number(rawValue))
                                      );
                                setEditedEntry({
                                  ...editedEntry,
                                  expectedScores: {
                                    ...editedEntry.expectedScores,
                                    closing: value,
                                  },
                                });
                              }}
                              type="number"
                              inputProps={{ min: 1, max: 5 }}
                            />
                          ) : (
                            row.expectedScores.closing
                          )}
                        </TableCell>
                        <TableCell sx={{ width: '14.28%' }}>
                          {editingIndex === index ? (
                            <Stack direction="row" spacing={1}>
                              <IconButton size="small" onClick={handleSaveEdit}>
                                <Check sx={{ fontSize: 18 }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={handleCancelEdit}
                              >
                                <Clear sx={{ fontSize: 18 }} />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleEditRecommendation(index, row)
                                }
                              >
                                <Edit sx={{ fontSize: 18 }} />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleDeleteRecommendation(index)
                                }
                              >
                                <Remove sx={{ fontSize: 18 }} />
                              </IconButton>
                            </Stack>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {personas && behaviors && (
                <Box
                  sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}
                >
                  <Select
                    value={newEntry.persona || ''}
                    onChange={(e) =>
                      setNewEntry({ ...newEntry, persona: e.target.value })
                    }
                    displayEmpty
                    size="small"
                    sx={{ width: '14.28%' }}
                  >
                    <MenuItem disabled value="">
                      Select Persona
                    </MenuItem>
                    {personas.map((persona) => (
                      <MenuItem key={persona._id} value={persona}>
                        {persona.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <Select
                    value={newEntry.mood || ''}
                    onChange={(e) =>
                      setNewEntry({ ...newEntry, mood: e.target.value })
                    }
                    displayEmpty
                    size="small"
                    sx={{ width: '14.28%' }}
                  >
                    <MenuItem disabled value="">
                      Mood
                    </MenuItem>
                    {behaviors &&
                      behaviors?.mood?.map((mood) => (
                        <MenuItem key={mood.id} value={mood}>
                          {mood.name}
                        </MenuItem>
                      ))}
                  </Select>

                  <Select
                    value={newEntry.tone || ''}
                    onChange={(e) =>
                      setNewEntry({ ...newEntry, tone: e.target.value })
                    }
                    displayEmpty
                    size="small"
                    sx={{ width: '14.28%' }}
                  >
                    <MenuItem disabled value="">
                      Tone
                    </MenuItem>
                    {behaviors?.tone?.map((tone) => (
                      <MenuItem key={tone.id} value={tone}>
                        {tone.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <Select
                    value={newEntry.difficulty || ''}
                    onChange={(e) =>
                      setNewEntry({ ...newEntry, difficulty: e.target.value })
                    }
                    displayEmpty
                    size="small"
                    sx={{ width: '14.28%' }}
                  >
                    <MenuItem disabled value="">
                      Difficulty
                    </MenuItem>
                    {behaviors?.difficulty?.map((difficulty) => (
                      <MenuItem key={difficulty.id} value={difficulty}>
                        {difficulty.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <TextField
                    size="small"
                    placeholder="1"
                    sx={{ width: '14.28%' }}
                    value={newEntry.expectedScores.opener}
                    onChange={(e) => {
                      const rawValue = e.target.value;
                      const value =
                        rawValue === ''
                          ? ''
                          : Math.max(1, Math.min(5, Number(rawValue)));
                      setNewEntry({
                        ...newEntry,
                        expectedScores: {
                          ...newEntry.expectedScores,
                          opener: value,
                        },
                      });
                    }}
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                  />
                  <TextField
                    size="small"
                    placeholder="3"
                    sx={{ width: '14.28%' }}
                    value={newEntry.expectedScores.discovery}
                    onChange={(e) => {
                      const rawValue = e.target.value;
                      const value =
                        rawValue === ''
                          ? ''
                          : Math.max(1, Math.min(5, Number(rawValue)));
                      setNewEntry({
                        ...newEntry,
                        expectedScores: {
                          ...newEntry.expectedScores,
                          discovery: value,
                        },
                      });
                    }}
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                  />
                  <TextField
                    size="small"
                    placeholder="5"
                    sx={{ width: '14.28%' }}
                    value={newEntry.expectedScores.closing}
                    onChange={(e) => {
                      const rawValue = e.target.value;
                      const value =
                        rawValue === ''
                          ? ''
                          : Math.max(1, Math.min(5, Number(rawValue)));
                      setNewEntry({
                        ...newEntry,
                        expectedScores: {
                          ...newEntry.expectedScores,
                          closing: value,
                        },
                      });
                    }}
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                  />
                  <IconButton color="primary" onClick={handleAddNewEntry}>
                    <Check />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      setNewEntry({
                        persona: '',
                        mood: '',
                        tone: '',
                        difficulty: '',
                        expectedScores: {
                          opener: '',
                          discovery: '',
                          closing: '',
                        },
                      })
                    }
                  >
                    <Clear />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Stack>
        {/* <Box display={'flex'} gap={2} alignItems={'center'} sx={{ mt: 2 }}>
          <Button
            fullWidth
            sx={{
              border: '1px solid #5B706F',
              color: '#5B706F',
              height: '46px',
              '&:hover': {
                backgroundColor: '#D3D6D6FF',
              },
            }}
            startIcon={<ArchiveIcon />}
          >
            Archive Assignment
          </Button>
          <Button
            fullWidth
            sx={{
              border: '1px solid #E03838',
              color: '#E03838',
              height: '46px',
              '&:hover': {
                backgroundColor: '#FDC8C8FF',
              },
            }}
            startIcon={<DeleteIcon />}
          >
            Delete Assignment
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default EditAssignment;
