import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { EmptyState } from 'components/CustomMUI';
import dateFormat from 'dateformat';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';

const InfoRow = ({ label, value, chips }) => (
  <Box sx={{ py: 2, borderBottom: '1px solid #dae8e7' }}>
    <Grid container>
      <Grid item xs={3}>
        <Typography sx={{ color: '#243030', fontWeight: 500 }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {chips ? (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {value.map((chip, index) => (
              <Chip
                key={index}
                label={chip}
                icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
                sx={{
                  bgcolor: '#F5F9F9',
                  color: '#00706C',
                  border: '1px solid #DAE8E7',
                  '& .MuiChip-icon': { color: '#00706C' },
                }}
              />
            ))}
          </Box>
        ) : (
          <Typography
            sx={{
              color: '#5B706F',
              fontSize: '16px',
              fontWeight: 300,
              textTransform: 'capitalize',
            }}
          >
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  </Box>
);

const calculateProgress = (assignedCalls) => {
  if (!assignedCalls || assignedCalls.length === 0) return 0;
  const completedCalls = assignedCalls.filter(
    (call) => call.callCompleted
  ).length;
  return Math.round((completedCalls / assignedCalls.length) * 100);
};
const ViewAssignment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const userId = pathSegments[pathSegments.length - 1];
  const assignmentId = pathSegments[pathSegments.length - 2];
  const [data, setData] = useState([]);
  const { setDocId } = usePersonaStore();

  const { call: getAssignmentDetails } = useApi({
    fetcher: organizationAPI.getAssignmentDetails,
  });

  useEffect(() => {
    const fetchAssignmentById = async () => {
      const response = await getAssignmentDetails({ userId, assignmentId });
      setData(response.data);
    };
    fetchAssignmentById();
  }, []);

  const handleRedirectToCallDetailsPage = (itemArray) => {
    if (itemArray.length > 0) {
      itemArray.forEach((item) => {
        if (item.userId === userId) {
          setDocId(item.convoTranscriptId);
          navigate(`/airoleplaycall/calldetails`);
        }
      });
    }
  };
  if (data) {
    const progress = calculateProgress(data.assignedCalls);
    const completedCalls = data.assignedCalls
      ? data.assignedCalls.filter((call) => call.callCompleted).length
      : 0;
    const totalCalls = data.assignedCalls ? data.assignedCalls.length : 0;
    return (
      <Container maxWidth="xl" sx={{ p: 4, bgcolor: '#fff', height: '100vh' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
          <Typography
            variant="h4"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '28px',
              fontWeight: 300,
              letterSpacing: '-0.087px',
            }}
          >
            View Assignment: {data.assignee || 'Unknown'}
          </Typography>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Button
              startIcon={<ArrowBackIosIcon fontSize="small" />}
              sx={{
                border: '1px solid #00706C',
                padding: '6px 16px',
                color: '#00706C',
                bgcolor: '#fff',
                '&:hover': { bgcolor: '#DFFCFBFF' },
              }}
              onClick={() => navigate(`/assignment/details/${assignmentId}`)}
            >
              Return
            </Button>
            {/* <Button
              startIcon={<CreateIcon />}
              onClick={() => setDrawerOpen(true)}
              sx={{
                border: '1px solid #00706C',
                padding: '6px 16px',
                color: '#00706C',
                bgcolor: '#fff',
                '&:hover': { bgcolor: '#DFFCFBFF' },
              }}
            >
              Edit Assignment
            </Button> */}
          </Box>
        </Box>
        <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />

        <Paper sx={{ mb: 4, p: 3, boxShadow: 'none' }}>
          <Typography
            variant="h4"
            sx={{
              color: '#243030',
              fontFamily: 'Lexend',
              fontSize: '20px',
              fontWeight: 300,
              letterSpacing: '-0.087px',
            }}
          >
            Basic Information
          </Typography>
          <Divider sx={{ border: '1px solid #dae8e7', my: '10px' }} />
          <InfoRow label="Assignee" value={data.assignee || 'Unknown'} />
          <InfoRow
            label="Assignee Email"
            value={data.assigneeEmail || 'Unknown'}
          />
          {/* <InfoRow label="Team Position" value={mockData.teams} chips={true} /> */}
          <InfoRow
            label="Assignment Name"
            value={data.assignmentName || 'Unknown'}
          />
          <InfoRow label="Status" value={data.status || 'Unknown'} />
          <InfoRow
            label="Due Date"
            value={dateFormat(data.dueDate, 'mmm d, yyyy') || 'Unknown'}
          />
        </Paper>

        {data.assignedCalls && (
          <Box sx={{ p: 3 }}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: '#243030',
                  fontFamily: 'Lexend',
                  fontSize: '20px',
                  fontWeight: 300,
                  letterSpacing: '-0.087px',
                }}
              >
                Assignment Progress
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography sx={{ color: '#243030' }}>
                  {completedCalls} of {totalCalls}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: '600px',
                    flex: 1,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: '#DAE8E7',
                    '& .MuiLinearProgress-bar': {
                      bgcolor: '#00706C',
                    },
                    border: '1px solid #B3C5C4FF',
                  }}
                />
              </Box>
            </Box>
            <Divider sx={{ border: '1px solid #dae8e7', my: '10px' }} />
          </Box>
        )}
        {data.assignedCalls && (
          <TableContainer
            component={Paper}
            sx={{ boxShadow: 'none', width: '98%', px: 2 }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: '#F5F9F9' }}>
                  <TableCell colSpan={5} sx={{ border: 'none' }} />
                  <TableCell
                    align="center"
                    colSpan={4}
                    sx={{ color: '#5B706F', fontSize: '12px', fontWeight: 300 }}
                  >
                    ACHIEVED SCORES
                  </TableCell>
                </TableRow>
                <TableRow sx={{ bgcolor: '#F5F9F9' }}>
                  <TableCell>Call Recipient</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Mood</TableCell>
                  <TableCell>Tone</TableCell>
                  <TableCell>Difficulty</TableCell>
                  <TableCell align="center">Opener</TableCell>
                  <TableCell align="center">Discovery</TableCell>
                  <TableCell align="center">Closing</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.assignedCalls.map((call, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textTransform: 'capitalize' }}>
                      {call.personaName}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        {call.callCompleted ? (
                          <CheckCircleIcon
                            sx={{ color: '#00706C', fontSize: 16 }}
                          />
                        ) : (
                          <RadioButtonUncheckedIcon
                            sx={{ color: '#DAE8E7', fontSize: 16 }}
                          />
                        )}
                        {call.callCompleted ? 'Completed' : 'Pending'}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>
                      {call.mood.name}
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>
                      {call.tone.name}
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>
                      {call.difficulty.name}
                    </TableCell>
                    <TableCell align="center">
                      {call.expectedScores.opener}
                    </TableCell>
                    <TableCell align="center">
                      {call.expectedScores.discovery}
                    </TableCell>
                    <TableCell align="center">
                      {call.expectedScores.closing}
                    </TableCell>
                    <TableCell align="center">
                      {Object.values(call.expectedScores).reduce(
                        (a, b) => a + b,
                        0
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {call.userIds.length > 0 ? (
                        <IconButton
                          size="small"
                          sx={{ color: '#00706C' }}
                          onClick={() =>
                            handleRedirectToCallDetailsPage(call.userIds)
                          }
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      ) : (
                        <VisibilityOffIcon sx={{ cursor: 'not-allowed' }} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    );
  } else {
    <EmptyState />;
  }
};

export default ViewAssignment;
