import { Add, Search, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { apis } from 'api/api';
// import DeleteDialog from 'components/Dialog/DeleteDialog';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useDialogStore, useItemStore } from 'store/useStore';
import { StoreKeys } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import { DataTable } from '../../components/Table/Table';
import { TableSkeleton } from '../../components/Table/TableSkeleton';
import { colorPalette } from '../../theme/colors';
// import { EditDialog } from './common/EditDialog';
// import DeleteDialog from './common/DeleteDialog';

const ModuleListTableColumns = [
  { id: 'createdAt', label: 'Date and Time' },
  { id: 'partnerName', label: 'Element Updated' },
  { id: 'duration', label: 'Executed by' },
  { id: 'formatOfInstruction', label: 'Event Type' },
  { id: 'supportStructure', label: 'Summary/Excerpt' },

  { id: 'actions', label: 'Actions' },
];
const DialogOpenKey = 'journeyDetails';
export const EventLogs = () => {
  const user = useAuthStore((state) => state.loggedUser);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);

  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const navigate = useNavigate();
  const { call: fetchJourneys, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getJourneyList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.JOURNEYS, value: updatedData });
    },
  });

  const { call: deleteJourney } = useApi({
    fetcher: apis.deleteJourney,
    successMessage: 'Journey deleted successfully',
  });

  useEffect(() => {
    fetchJourneys();
  }, []);

  const searchFields = ['title', 'createdAt'];
  const { setSearchTerm, filteredData } = useTableSearch({
    data: itemStore[StoreKeys.JOURNEYS],
    searchFields,
  });

  const searchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  const header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Event Logs</Typography>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {header}
      <Divider />
      <Box
        sx={{
          background: colorPalette.background.paper,
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Stack direction="column" spacing={5}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button
              disabled
              onClick={() => {
                navigate('/journey/create');
              }}
              variant="contained"
              startIcon={<Add />}
            >
              New Event
            </Button>
            {searchBar}
          </Stack>
          {isFetching ? (
            <TableSkeleton column={ModuleListTableColumns} />
          ) : (
            <DataTable
              columns={ModuleListTableColumns}
              sort={true}
              data={filteredData?.map((item) => ({
                ...item,
                actions: (
                  <Button onClick={() => {}} startIcon={<Visibility />}>
                    View
                  </Button>
                ),
                name: `${item.firstName} ${item.lastName}`,
                createdAt: getOriginalZonedDate({
                  date: new Date(item.createdAt || new Date()),
                }),
                status: (
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
              }))}
            />
          )}
        </Stack>
      </Box>
      {/* <DeleteDialog />
      <EditDialog forItem={DialogOpenKey} /> */}
    </Stack>
  );
};
