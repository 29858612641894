import Visibility from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CalendarIcon } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import { PhoneIcon } from 'svg/CustomSVGIcon';
import {
  difficultyColors,
  moodColors,
  toneColors,
} from 'utils/const/status-color';
export const CallInfo = ({
  title,
  dateTime,
  duration,
  id,
  mood,
  tone,
  difficulty,
}) => {
  const { setDocId } = usePersonaStore();
  const navigate = useNavigate();
  const handleShowScorecard = () => {
    if (id) {
      setDocId(id);
      navigate(`/airoleplaycall/calldetails`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box>
        <Typography
          color="#92a7a5"
          fontSize={14}
          fontWeight={500}
          fontFamily="Lexend"
          noWrap
        >
          {title}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {mood && (
            <Typography
              color={
                moodColors[String(mood).toLowerCase()] || moodColors.default
              }
              fontSize={12}
              fontWeight={500}
              fontFamily="Lexend"
              noWrap
              sx={{
                width: 'fit-content',
                padding: '1px 8px',
                border: `1px solid ${
                  moodColors[String(mood).toLowerCase()] || moodColors.default
                }`,
                borderRadius: '20px',
              }}
            >
              {mood}
            </Typography>
          )}
          {tone && (
            <Typography
              color={
                toneColors[String(tone).toLowerCase()] || toneColors.default
              }
              fontSize={12}
              fontWeight={500}
              fontFamily="Lexend"
              noWrap
              sx={{
                width: 'fit-content',
                padding: '1px 8px',
                border: `1px solid ${
                  toneColors[String(tone).toLowerCase()] || toneColors.default
                }`,
                borderRadius: '20px',
              }}
            >
              {tone}
            </Typography>
          )}
          {difficulty && (
            <Typography
              color={
                difficultyColors[String(difficulty).toLowerCase()] ||
                difficultyColors.default
              }
              fontSize={12}
              fontWeight={500}
              fontFamily="Lexend"
              noWrap
              sx={{
                width: 'fit-content',
                padding: '1px 8px',
                border: `1px solid ${
                  difficultyColors[String(difficulty).toLowerCase()] ||
                  difficultyColors.default
                }`,
                borderRadius: '20px',
              }}
            >
              {difficulty}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <CalendarIcon sx={{ color: '#92a7a5', fontSize: '20px' }} />
          <Typography
            color="#92a7a5"
            fontSize={14}
            fontWeight={500}
            fontFamily="Lexend"
            noWrap
          >
            {dateTime}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <PhoneIcon fill={'#92a7a5'} />
          <Typography
            color="#92a7a5"
            fontSize={14}
            fontWeight={500}
            fontFamily="Lexend"
            noWrap
            sx={{ width: '70px' }}
          >
            {duration}
          </Typography>
        </Box>
        <IconButton style={{ color: '#00706C' }} onClick={handleShowScorecard}>
          <Visibility fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CallInfo;
