import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import React, { useState } from 'react';
import { colorPalette } from 'theme/colors';

const getComponentProps = ({ formType, componentType }) => {
  const baseProps = {
    stack: {
      direction: 'row',
      sx: { alignItems: 'center' },
    },
    input: {
      fullWidth: true,
      sx: { flex: 1 },
    },
  };

  const addTypeProps = {
    stack: {
      sx: {
        background: colorPalette.background.paper,
        px: 2,
        py: 4,
        borderRadius: 2,
      },
    },
    input: {
      sx: { flex: 1 },
      size: 'small',
    },
    select: { size: 'small' },
  };

  return formType === 'ADD'
    ? addTypeProps[componentType]
    : baseProps[componentType];
};

export const FormField = ({
  formType = 'EDIT',
  field,
  register,
  errors,
  watch,
  loading,
  state,
  stateUpdater,
  hookFormUpdater,
  hookFormTrigger,
  disabled,
  onChange,
}) => {
  const { name, label, type, options, ...rest } = field;
  const [inputValue, setInputValue] = useState('');

  const handleMultiSelectChange = ({ name, values }) => {
    hookFormUpdater(name, values);
    hookFormTrigger(name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    hookFormUpdater(name, value);
    hookFormTrigger(name);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      const newValue = inputValue.trim();
      const currentValues = watch(name) || [];
      if (!currentValues.includes(newValue)) {
        const updatedValues = [...currentValues, newValue];
        handleMultiSelectChange({ name, values: updatedValues });
        setInputValue('');
      }
    }
  };

  const renderField = () => {
    switch (type) {
      case 'text':
      case 'number':
        return (
          <TextField
            type={type}
            {...getComponentProps({ formType, componentType: 'input' })}
            disabled={disabled}
            label={formType === 'ADD' ? label : ''}
            value={watch(name) || ''}
            {...register(name)}
            error={!!errors[name]}
            helperText={errors[name] ? errors[name].message : ''}
            onChange={(e) => {
              handleInputChange(e);
              if (onChange) onChange(e);
            }}
            multiline={rest.multiline}
            rows={4}
          />
        );
      case 'select':
        return (
          <FormControl
            fullWidth={formType === 'EDIT' ? true : false}
            sx={{ flex: 1 }}
          >
            {formType === 'ADD' && (
              <InputLabel id={name} size="small">
                {label}
              </InputLabel>
            )}
            <Select
              {...getComponentProps({ formType, componentType: 'select' })}
              {...register(name)}
              disabled={disabled}
              label={formType === 'ADD' ? label : ''}
              labelId={name}
              id={name}
              error={!!errors[name]}
              value={!loading ? watch(name) || '' : ''}
              onChange={(e) => {
                handleInputChange(e);
                hookFormUpdater(name, e.target.value, { shouldValidate: true });
                if (onChange) onChange(e);
              }}
            >
              {loading ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                  <span style={{ marginLeft: 10 }}>Loading...</span>
                </MenuItem>
              ) : (
                options?.map((option, i) => (
                  <MenuItem key={`${option.value}-${i}`} value={option.value}>
                    {option.label || ''}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText error={!!errors[name]}>
              {errors[name]?.message || ''}
            </FormHelperText>
          </FormControl>
        );
      case 'multiSelect':
        return (
          <FormControl fullWidth sx={{ flex: 1 }}>
            <MultiSelect
              {...getComponentProps({ formType, componentType: 'select' })}
              {...register(name)}
              disabled={disabled}
              creatable={rest.autoComplete}
              error={!!errors[name]}
              errorMessage={errors[name]?.message || ''}
              loading={loading}
              options={options}
              label={formType === 'ADD' ? label : ''}
              selectedValues={watch(name) || []}
              handleSelectionChange={(e) => {
                const selectedValues = e.target.value;
                handleMultiSelectChange({ name, values: selectedValues });
                if (onChange) onChange(e);
              }}
              handleDelete={(valueToDelete) => {
                const updatedValues = (watch(name) || []).filter(
                  (value) => value !== valueToDelete
                );
                handleMultiSelectChange({ name, values: updatedValues });
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) =>
                setInputValue(newInputValue)
              }
              onKeyDown={handleKeyDown}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    onKeyDown: handleKeyDown,
                  }}
                />
              )}
            />
          </FormControl>
        );
      default:
        return null;
    }
  };

  if (formType === 'ADD') {
    return renderField();
  }

  return (
    <Stack {...getComponentProps({ componentType: 'stack', formType: 'EDIT' })}>
      {formType === 'EDIT' && (
        <Typography sx={{ flex: 0.5 }}>{label}</Typography>
      )}
      {renderField()}
    </Stack>
  );
};

export default React.memo(FormField);
