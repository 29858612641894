import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingOverlay from 'components/LoadingOverlay';
import { useDialog, useUserContext } from 'pages/Settings/context';
import { useForm } from 'react-hook-form';
import { EditDialogWrapper, FormField } from '../../common';
import { addPartner } from '../validation/schema';

const blankPartnerInfo = {
  firstName: '',
  lastName: '',
  email: '',
};

export const EditDetailsDialog = ({ onClose }) => {
  const { closeDialog, dialogData } = useDialog();

  const [userInfo, setUserInfo] = useState(blankPartnerInfo);
  const { update, isUpdating } = useUserContext();
  const handleClose = () => {
    if (onClose) onClose();
    reset();
    closeDialog();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(addPartner),
    mode: 'onChange',
    defaultValues: { role: 'PARTNER' },
  });

  const updatePartnerInfo = async (data) => {
    const updatedUserInfo = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      companyName: data.companyName,
      companyType: data.companyType,
    };
    await update({ userId: dialogData.id, data: updatedUserInfo });
    reset();
    handleClose();
  };

  useEffect(() => {
    const initialUserInfo = {
      firstName: dialogData?.firstName || '',
      lastName: dialogData?.lastName || '',
      email: dialogData?.email || '',
    };
    reset(dialogData);
    setUserInfo(initialUserInfo);
  }, [dialogData, reset]);

  const renderFormFields = () => {
    const fields = [
      {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email Address',
        type: 'text',
      },
      {
        name: 'companyName',
        label: 'Company Name',
        type: 'text',
      },
      {
        name: 'companyType',
        label: 'Company Type',
        type: 'text',
      },
    ];

    return fields.map((item) => (
      <FormField
        key={item.name}
        field={item}
        register={register}
        errors={errors}
        watch={watch}
        state={userInfo}
        stateUpdater={setUserInfo}
        hookFormUpdater={setValue}
        hookFormTrigger={trigger}
      />
    ));
  };

  return (
    <>
      {isUpdating && <LoadingOverlay />}
      <EditDialogWrapper
        onSubmit={handleSubmit(updatePartnerInfo)}
        dialogType={'edit'}
        title={'Update Partner Info'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {renderFormFields()}
        </Box>
      </EditDialogWrapper>
    </>
  );
};
