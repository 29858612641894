import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from '@mui/material';

const FormFields = ({
  type,
  name,
  formData,
  handleChange,
  tagInput,
  options = [],
  label,
  placeholder,
  props,
}) => {
  switch (type) {
    case 'textfield':
      return (
        <TextField
          fullWidth
          variant="outlined"
          label={label}
          value={formData[name] || ''}
          onChange={(e) => handleChange(name, e.target.value)}
          {...props}
        />
      );

    case 'taginput':
      return (
        <Autocomplete
          multiple
          freeSolo={tagInput}
          options={options}
          value={formData[name] || []}
          onChange={(_, newValue) => handleChange(name, newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, idx) => (
              <Chip
                key={idx}
                {...getTagProps({ index: idx })}
                label={option}
                sx={{
                  backgroundColor: '#DAE8E7',
                  m: 0.5,
                  '& .MuiChip-deleteIcon': {
                    color: '#5B706F',
                  },
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={placeholder}
            />
          )}
          {...props}
        />
      );

    case 'checkbox':
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={formData[name] || false}
              onChange={(e) => handleChange(name, e.target.checked)}
              name={name}
              {...props}
            />
          }
          label={label}
        />
      );
    case 'datepicker':
      return (
        <TextField
          fullWidth
          label={label}
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formData[name] || ''}
          onChange={(e) => handleChange(name, e.target.value)}
          {...props}
        />
      );
    default:
      return null;
  }
};

export default FormFields;
