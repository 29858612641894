import { Box, Divider, Stack, Typography } from '@mui/material';
import { UserProvider, useUserContext } from 'pages/Settings/context';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogStore } from 'store/useStore';

export const JourneyDetails = () => {
  const editDialogState = useDialogStore((state) => state.editDialog);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useFormContext();

  const {
    data: partnerList,
    get: getPartnerList,
    isFetching: fetchingPartner,
  } = useUserContext();

  useEffect(() => {
    reset(editDialogState?.data);
  }, [editDialogState?.data]);

  useEffect(() => {
    getPartnerList();
  }, []);

  const renderFormFields = () => {
    const infoFields = [
      { name: 'title', label: 'Journey Title', type: 'text' },
      {
        name: 'partnerId',
        label: 'Partner',
        type: 'select',
        options: partnerList?.map((partner) => ({
          value: partner.id,
          label: `${partner.firstName} ${partner.lastName}`,
        })),
      },
      {
        name: 'viewVentureList',
        label: 'Ventures Who Can See This Journey',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
    ];

    const settingsFields = [
      [
        {
          name: 'duration',
          label: 'Journey Duration(months)',
          type: 'number',
        },
        {
          name: 'meetingsPerMonth',
          label: 'Meetings Per Month',
          type: 'number',
        },
        {
          name: 'meetingsPerWeek',
          label: 'Meetings Per Week',
          type: 'number',
        },
        {
          name: 'numberOfHoursPerMeeting',
          label: 'Hours Per Meeting',
          type: 'number',
        },
      ],
      [
        {
          name: 'formatOfInstruction',
          label: 'Format Of Instruction',
          type: 'select',
          options: [
            { value: 'Online', label: 'Online' },
            { value: 'In-Person', label: 'In-Person' },
            { value: 'Hybrid', label: 'Hybrid' },
          ],
        },
        {
          name: 'supportStructure',
          label: 'Support Structure',
          type: 'select',
          options: [
            { label: 'Facilitated', value: 'Facilitated' },
            { label: 'Independent', value: 'Independent' },
          ],
        },
        {
          name: 'language',
          label: 'Language',
          type: 'select',
          options: [
            { label: 'English', value: 'English' },
            { label: 'Japanese', value: 'Japanese' },
          ],
        },
      ],
      {
        name: 'additionalInstructions',
        label: 'Additional Instruction Prompts',
        type: 'text',
        multiline: true,
      },
    ];

    return (
      <>
        {infoFields.map((item, index) => (
          <FormField
            loading={fetchingPartner}
            formType="ADD"
            key={`${item.name}-${index}`}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
            onChange={(e) => {
              if (item.name === 'partnerId') {
                const partner = partnerList.find(
                  (item) => item.id === e.target.value
                );
                const partnerName = `${partner.firstName} ${partner.lastName}`;
                setValue('partnerName', partnerName);
              }
            }}
          />
        ))}
        <Typography variant="button">Module Settings</Typography>
        <Divider sx={{ borderColor: 'primary.main' }} />

        {settingsFields.map((item, index) => {
          // Check if the current item is an array
          if (Array.isArray(item)) {
            return (
              <Stack
                direction={'row'}
                spacing={2}
                key={`${item.name}-${index}`}
              >
                {item.map((nestedItem) => (
                  <FormField
                    formType="ADD"
                    key={`${nestedItem.name}-${index}`}
                    field={nestedItem}
                    register={register}
                    errors={errors}
                    watch={watch}
                    hookFormUpdater={setValue}
                    hookFormTrigger={trigger}
                  />
                ))}
              </Stack>
            );
          } else {
            return (
              <FormField
                formType="ADD"
                key={`settings-${item.name}`}
                field={item}
                register={register}
                errors={errors}
                watch={watch}
                hookFormUpdater={setValue}
                hookFormTrigger={trigger}
              />
            );
          }
        })}
      </>
    );
  };

  return (
    <UserProvider userType={'PARTNER'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {renderFormFields()}
      </Box>
    </UserProvider>
  );
};
