import {
  Edit,
  Group,
  MoreVert,
  PeopleOutline,
  PersonOutline,
} from '@mui/icons-material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import dateFormat from 'dateformat';
import { useApi } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from 'store/useStore';
import EditAssignment from '../Drawer/EditAssignment';
const renderAssignees = (assignees) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,

        maxWidth: '500px',
        overflow: 'auto',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {assignees.map((assignee, index) => (
        <Box
          key={index}
          sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
        >
          {assignee.type === 'user' && (
            <PersonOutline sx={{ fontSize: 16, color: 'text.secondary' }} />
          )}
          {assignee.type === 'team' && (
            <PeopleOutline sx={{ fontSize: 16, color: 'text.secondary' }} />
          )}
          <Typography variant="body2" component="span">
            {assignee.userName}
          </Typography>
          {/* {assignee.type && (
            <Typography
              variant="body2"
              color="text.secondary"
              component="span"
              sx={{
                backgroundColor:
                  assignee.type === 'user' ? '#A8E8FCFF' : '#8D8EFFFF',
                padding: '1px 6px',
                borderRadius: '12px',
              }}
            >
              {assignee.type}
            </Typography>
          )} */}
          {index < assignees.length - 1 && (
            <Typography variant="body2" color="text.secondary" component="span">
              +
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

const AssignmentsTable = ({ data, filter, search }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [assignmentId, setAssignmentId] = useState(null);
  const { render, setRender } = useStore();

  const { call } = useApi({
    fetcher: organizationAPI.deleteAssignmnet,
    successMessage: 'Assignment deleted successfully',
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, assignment) => {
    setAnchorEl(event.currentTarget);
    setSelectedAssignment(assignment);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAssignment(null);
  };

  const handleEditDrawer = () => {
    setAnchorEl(null);
    setDrawerOpen(true);
  };

  const handleRemoveClick = () => {
    setRemoveDialogOpen(true);
    handleMenuClose();
  };

  const handleRemoveConfirm = async () => {
    await call(assignmentId);
    if (!render) {
      setRender();
    }
    setRemoveDialogOpen(false);
    setSelectedAssignment(null);
    setAssignmentId(null);
  };

  const handleNavigateToAssignmentDetails = (id) => {
    setAnchorEl(null);
    setSelectedAssignment(null);
    navigate(`/assignment/details/${id}`);
  };

  const filteredData = data
    .filter((row) => filter === 'all' || filter.includes(row.status))
    .filter((row) => {
      if (!search && filter === 'all') return true;

      const searchLower = search.toLowerCase();
      const assignmentNameValid = row.assignmentName
        ? row.assignmentName.toLowerCase().includes(searchLower)
        : false;
      const dueDateValid = row.dueDate
        ? row.dueDate.toLowerCase().includes(searchLower)
        : false;
      const assigneesValid =
        row.assignedTo &&
        row.assignedTo.some((assignee) =>
          assignee.userName.toLowerCase().includes(searchLower)
        );

      return assignmentNameValid || dueDateValid || assigneesValid;
    });

  return (
    <>
      <Paper
        sx={{
          boxShadow: 'none',
          border: '1px solid #DAE8E7',
          borderRadius: '8px',
          padding: '10px',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Assignment Name</TableCell>
                <TableCell>Assigned to</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell sx={{ textTransform: 'capitalize' }}>
                      {row.assignmentName}
                    </TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>
                      {renderAssignees(row.assignedTo)}
                    </TableCell>
                    <TableCell>
                      {dateFormat(row.dueDate, 'mmm d, yyyy')}
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          backgroundColor:
                            row.status === 'all'
                              ? 'rgba(128, 128, 128, 0.5)'
                              : row.status === 'assigned'
                              ? 'rgba(64, 153, 255, 0.5)'
                              : row.status === 'active'
                              ? 'rgba(0, 200, 117, 0.5)'
                              : row.status === 'completed'
                              ? 'rgba(241, 217, 110, 0.5)'
                              : row.status === 'overdue'
                              ? 'rgba(253, 97, 97, 0.5)'
                              : row.status === 'archived'
                              ? 'rgba(128, 128, 128, 0.5)'
                              : 'rgba(80, 80, 80, 0.1)',
                          padding: '1px 10px',
                          borderRadius: '12px',
                          width: 'fit-content',
                          textTransform: 'capitalize',
                        }}
                      >
                        {row.status}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(event) => handleMenuOpen(event, row)}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 16px',
            backgroundColor: '#F8F9FA',
            borderRadius: '8px',
            margin: '16px 0',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Displaying {page * rowsPerPage + 1}-
            {Math.min((page + 1) * rowsPerPage, data.length)} of {data.length}{' '}
            entries
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <IconButton
                size="small"
                onClick={() => handleChangePage(page - 1)}
                disabled={page === 0}
                sx={{ p: 0.5 }}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="body2" color="text.secondary">
                Page {page + 1} of {Math.ceil(data.length / rowsPerPage)}
              </Typography>
              <IconButton
                size="small"
                onClick={() => handleChangePage(page + 1)}
                disabled={page >= Math.ceil(data.length / rowsPerPage) - 1}
                sx={{ p: 0.5 }}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Entries per Page
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {[10, 25, 50, 100].map((value) => (
                <Box
                  key={value}
                  onClick={() => handleChangeRowsPerPage({ target: { value } })}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: 0.5,
                  }}
                >
                  <input
                    type="radio"
                    checked={rowsPerPage === value}
                    onChange={() => {}}
                    id={`rows-${value}`}
                  />
                  <Typography
                    component="label"
                    htmlFor={`rows-${value}`}
                    variant="body2"
                    sx={{ cursor: 'pointer' }}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() =>
            handleNavigateToAssignmentDetails(selectedAssignment._id)
          }
        >
          <Group sx={{ mr: 1, fontSize: 20, my: 1 }} />
          View Assignees
        </MenuItem>
        <MenuItem onClick={handleEditDrawer}>
          <Edit sx={{ mr: 1, fontSize: 20, my: 1 }} />
          Edit Assignment
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRemoveClick();
            setAssignmentId(selectedAssignment._id);
          }}
          sx={{ color: 'error.main', my: 1 }}
        >
          <Box
            component="span"
            sx={{
              width: 20,
              height: 20,
              mr: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            🚫
          </Box>
          Remove
        </MenuItem>
      </Menu>

      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        PaperProps={{
          sx: { borderRadius: 2, maxWidth: 400, paddingY: '10px' },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', pt: 3 }}>
          <Box
            component="span"
            sx={{
              fontSize: '2rem',
              display: 'block',
              mb: 1,
            }}
          >
            <PersonOffIcon sx={{ color: '#FF0000FF', fontSize: '60px' }} />
          </Box>
          Remove Assignment
        </DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ mb: 3 }}>
            Are you sure you want to remove this assignment? This action cannot
            be undone.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={handleRemoveConfirm}
            >
              Yes
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setRemoveDialogOpen(false)}
            >
              No
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { width: { xs: '100%', sm: 1100 } },
        }}
      >
        {selectedAssignment && (
          <EditAssignment
            onClose={() => setDrawerOpen(false)}
            id={selectedAssignment._id}
          />
        )}
      </Drawer>
    </>
  );
};
export default AssignmentsTable;
