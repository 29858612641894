import { yupResolver } from '@hookform/resolvers/yup';
import { CloudUpload } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import apis from 'api/api';
import EditDialogWrapper from 'components/Dialog/DialogWrapper';
import { useApi } from 'hooks';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthStore, useDialogStore, useItemStore } from 'store';
import { StoreKeys } from 'utils';
import { addAsset } from './schema';
import { getStyles } from './styles';

export const UploadDialog = () => {
  const editDialogState = useDialogStore((state) => state.editDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);
  const user = useAuthStore((state) => state.loggedUser);
  const itemStore = useItemStore((state) => state.items);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const [selectedFile, setSelectedFile] = useState(null);

  const styles = getStyles();

  const { call: uploadDocument, isLoading: isFileUploading } = useApi({
    fetcher: apis.uploadDocument,
  });
  const { call: createAsset } = useApi({
    fetcher: apis.createAsset,
    successMessage: 'Asset created successfully',
  });
  const { call: updateAsset } = useApi({
    fetcher: apis.updateAsset,
    successMessage: 'Asset updated successfully',
  });

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
    handleSubmit,
  } = useForm({ mode: 'onChange', resolver: yupResolver(addAsset) });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const uploadResponse = await uploadDocument({ formData });

      if (uploadResponse.data) {
        setSelectedFile({ ...file, document: uploadResponse.data });
        reset({ name: file.name });
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const storeAsset = async () => {
    const assetToEdit = editDialogState?.data;
    const { document, name: selectedFileName } = selectedFile;
    const { link, key, name: documentName } = document;
    const fileName = selectedFileName || documentName;

    if (!link || !fileName) return;

    const data = {
      document: { link, key },
      name: fileName,
      uploadedBy: `${user.firstName} ${user.lastName}`,
    };

    if (assetToEdit?._id) {
      await updateAsset({ assetId: assetToEdit._id, data });

      const updatedAssets = itemStore[StoreKeys.ASSETS].map((asset) => {
        return asset._id === assetToEdit._id ? { ...asset, ...data } : asset;
      });

      updateItemStore({ key: StoreKeys.ASSETS, value: updatedAssets });
    } else {
      const response = await createAsset({ data });
      const updatedAssets = [...itemStore[StoreKeys.ASSETS], response.data];
      updateItemStore({
        key: StoreKeys.ASSETS,
        value: updatedAssets,
      });
    }

    setEditDialog({ isOpen: false, data: null });
  };

  const fillStoredData = () => {
    const assetToedit = editDialogState?.data;
    if (assetToedit?._id) {
      const dataToFill = { name: assetToedit?.name };
      setSelectedFile(assetToedit);
      reset(dataToFill);
    }
  };

  useEffect(() => {
    fillStoredData();

    return () => {
      setSelectedFile(null);
      reset({ name: '' });
    };
  }, [editDialogState?.data?._id]);

  return (
    <EditDialogWrapper
      title={'Upload Asset'}
      onSubmit={handleSubmit(storeAsset)}
      sx={styles.dialogWrapper}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={styles.fileInputContainer}
        >
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
            {isFileUploading ? (
              <CircularProgress color="success" />
            ) : (
              <CloudUpload sx={{ fontSize: 50, color: '#888' }} />
            )}
          </label>
          <Typography variant="body1" sx={{ mt: 1, textAlign: 'center' }}>
            {selectedFile
              ? `Selected File: ${watch('name')}`
              : 'Click here to attach your document, or drag and drop it here from your file browser.'}
          </Typography>
        </Box>

        <FormField
          formType="ADD"
          key={'name'}
          field={{ name: 'name', label: 'Asset Name', type: 'text' }}
          register={register}
          errors={errors}
          watch={watch}
          hookFormUpdater={setValue}
          hookFormTrigger={trigger}
          onChange={(e) => {
            setSelectedFile((prev) => ({ ...prev, name: e.target.value }));
          }}
        />
      </Box>
    </EditDialogWrapper>
  );
};
