import { Add, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import useStore from 'store/useStore';
import NewAssignment from './Drawer/NewAssignment';
import EmptyState from './EmptyState';
import AssignmentsTable from './Tabs/AssignmentsTable';

const statuses = [
  'all',
  'assigned',
  'active',
  'completed',
  'overdue',
  'archived',
];

const Assignments = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('all');
  const [activeTab, setActiveTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [assignments, setAssignments] = useState({
    activeAssignments: [],
    completedAssignments: [],
  });
  const { render, clearRender } = useStore();

  const { call: getAllConvoAssignments } = useApi({
    fetcher: organizationAPI.getAllConvoAssignments,
    successMessage: 'All assignments fetched successfully',
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchAllConvoAssignments = async () => {
      try {
        const response = await getAllConvoAssignments();
        const categorizedAssignments = categorizeAssignments(response.data);
        setAssignments(categorizedAssignments);
      } catch (error) {
        console.error('Error fetching assignments:', error);
      }
    };

    fetchAllConvoAssignments();
    if (render) {
      clearRender();
    }
  }, [render]);

  const categorizeAssignments = (data) => {
    return {
      activeAssignments: data.filter(
        (assignment) =>
          assignment.status === 'assigned' || assignment.status === 'active'
      ),
      completedAssignments: data.filter(
        (assignment) =>
          assignment.status === 'completed' ||
          assignment.status === 'overdue' ||
          assignment.status === 'archived'
      ),
    };
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            color: '#243030',
            fontFamily: 'Lexend',
            fontSize: '28px',
            fontWeight: 300,
            letterSpacing: '-0.087px',
          }}
        >
          Assignments
        </Typography>
        <Button
          startIcon={<Add />}
          onClick={() => setDrawerOpen(true)}
          sx={{
            border: '1px solid #00706C',
            padding: '4px 10px',
            color: '#00706C',
            bgcolor: '#fff',
            '&:hover': { bgcolor: '#DFFCFBFF' },
          }}
        >
          Create New Assignment
        </Button>
      </Box>
      <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <TextField
          fullWidth
          placeholder="Search by name/keyword"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            sx: { height: '100%' },
          }}
          sx={{
            bgcolor: 'background.paper',
            height: '50px',
            '& .MuiInputBase-root': { height: '100%' },
          }}
        />
        <Select
          value={status}
          onChange={handleStatusChange}
          sx={{
            minWidth: 300,
            bgcolor: 'background.paper',
            height: '50px',
            textTransform: 'capitalize',
          }}
        >
          {statuses.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{ textTransform: 'capitalize' }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ width: '100%' }}
        >
          <Tab
            label="Ongoing Assignments"
            sx={{
              flex: 1,
              maxWidth: 'none',
            }}
            icon={
              <span
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: '#00A76F',
                  display: 'inline-block',
                  marginRight: 8,
                }}
              />
            }
            iconPosition="start"
          />
          <Tab
            label="Past Assignments"
            sx={{
              flex: 1,
              maxWidth: 'none',
            }}
            icon={
              <span
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: '#637381',
                  display: 'inline-block',
                  marginRight: 8,
                }}
              />
            }
            iconPosition="start"
          />
        </Tabs>
      </Box>

      <Box sx={{ mt: 3 }}>
        {activeTab === 0 ? (
          assignments.activeAssignments.length > 0 ? (
            <AssignmentsTable
              data={assignments.activeAssignments}
              filter={status}
              search={searchTerm}
            />
          ) : (
            <EmptyState onCreateClick={() => setDrawerOpen(true)} />
          )
        ) : assignments.completedAssignments.length > 0 ? (
          <AssignmentsTable
            data={assignments.completedAssignments}
            filter={status}
            search={searchTerm}
          />
        ) : (
          <EmptyState onCreateClick={() => setDrawerOpen(true)} />
        )}
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { width: { xs: '100%', sm: 1100 } },
        }}
      >
        <NewAssignment onClose={() => setDrawerOpen(false)} />
      </Drawer>
    </Container>
  );
};
export default Assignments;
