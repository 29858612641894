import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/muiTheme';
import { SnackbarProvider } from './SnackbarContext';
import { UserProvider } from './UserContext';

const AppContextProvider = ({ children }) => {
  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </ThemeProvider>
    </UserProvider>
  );
};

export default AppContextProvider;
