import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import confetti from 'canvas-confetti';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AssignmentComplete = () => {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const duration = 2000;
    const animationEnd = Date.now() + duration;
    const confettiAnimation = () => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        setShowContent(true);
        return;
      }

      const particleCount = 5;
      confetti({
        particleCount,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: ['#00796b', '#2196f3', '#ff9800', '#e91e63'],
      });

      confetti({
        particleCount,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: ['#00796b', '#2196f3', '#ff9800', '#e91e63'],
      });

      requestAnimationFrame(confettiAnimation);
    };

    confettiAnimation();
    const redirectTimeout = setTimeout(() => {
      navigate('/convo/assignments');
    }, 22000);

    return () => clearTimeout(redirectTimeout);
  }, []);

  return (
    <Container maxWidth="xl" sx={{ p: 4, bgcolor: '#fff', height: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            color: '#243030',
            fontFamily: 'Lexend',
            fontSize: '28px',
            fontWeight: 300,
            letterSpacing: '-0.087px',
          }}
        >
          Assignment
        </Typography>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Button
            startIcon={<ArrowBackIosIcon fontSize="small" />}
            sx={{
              border: '1px solid #00706C',
              padding: '6px 16px',
              color: '#00706C',
              bgcolor: '#fff',
              '&:hover': { bgcolor: '#DFFCFBFF' },
            }}
            onClick={() => navigate('/convo/assignments')}
          >
            Return
          </Button>
        </Box>
      </Box>
      <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />
      <Box
        sx={{
          height: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={2}
          sx={{
            maxWidth: 600,
            mx: 'auto',
            mt: 15,
            p: 4,
            textAlign: 'center',
            borderRadius: 2,
            opacity: showContent ? 1 : 0,
            transform: showContent ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.4s ease-out, transform 0.4s ease-out',
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: 64,
              color: '#00796b',
              mb: 2,
              animation: showContent ? 'bounceIn 0.5s ease-out' : 'none',
              '@keyframes bounceIn': {
                '0%': {
                  transform: 'scale(0)',
                },
                '50%': {
                  transform: 'scale(1.2)',
                },
                '100%': {
                  transform: 'scale(1)',
                },
              },
            }}
          />

          <Typography
            variant="h4"
            component="h2"
            sx={{
              mb: 2,
              fontWeight: 400,
              color: '#333',
            }}
          >
            Assignment Completed
          </Typography>

          <Typography
            sx={{
              mb: 3,
              color: '#666',
              lineHeight: 1.6,
            }}
          >
            Congratulations, you have successfully completed this assignment.
            You will be redirected to the dashboard momentarily, from where you
            can pursue other assignments, or review this one.
          </Typography>

          <Link
            href="#"
            sx={{
              color: '#2196f3',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Click here if you are not automatically redirected.
          </Link>
        </Paper>
      </Box>
    </Container>
  );
};

export default AssignmentComplete;
