import { create } from 'zustand';

const useStore = create((set) => ({
  userId: localStorage.getItem('userId') || null,
  organizationId: localStorage.getItem('organizationId') || null,
  userData: localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData'))
    : null,

  dataUpdated: false,
  pathName: window.location.pathname || '/',
  callEnded: false,
  render: false,

  assignment: {
    call: false,
    userId: null,
    assignmentId: null,
  },

  setUserId: (userId) => {
    localStorage.setItem('userId', userId);
    set({ userId });
  },
  getUserId: () => set((state) => state.userId),
  clearUserId: () => {
    localStorage.removeItem('userId');
    set({ userId: null });
  },

  setOrganizationId: (organizationId) => {
    localStorage.setItem('organizationId', organizationId);
    set({ organizationId });
  },
  getOrganizationId: () => set((state) => state.organizationId),
  clearOrganizationId: () => {
    localStorage.removeItem('organizationId');
    set({ organizationId: null });
  },

  setUserData: (userData) => {
    localStorage.setItem('userData', JSON.stringify(userData));
    set({ userData });
  },
  getUserData: () => set((state) => state.userData),
  clearUserData: () => {
    localStorage.removeItem('userData');
    set({ userData: null });
  },

  setDataUpdated: () => set({ dataUpdated: true }),
  clearDataUpdated: () => set({ dataUpdated: false }),

  setPathName: (pathName) => set({ pathName }),
  clearPathName: () => set({ pathName: '/' }),

  setCallEnded: () => set({ callEnded: true }),
  clearCallEnded: () => set({ callEnded: false }),

  setRender: () => set({ render: true }),
  clearRender: () => set({ render: false }),

  setAssignment: (userId, assignmentId) =>
    set((state) => ({
      assignment: {
        ...state.assignment,
        call: true,
        userId: userId || state.assignment.userId,
        assignmentId: assignmentId || state.assignment.assignmentId,
      },
    })),

  clearAssignment: () =>
    set((state) => ({
      assignment: {
        ...state.assignment,
        call: false,
        userId: null,
        assignmentId: null,
      },
    })),
}));

export const useItemStore = create((set) => ({
  items: {},
  addItem: ({ key, value }) =>
    set((state) => ({ items: { ...state.items, [key]: value } })),
  updateItem: ({ key, value }) =>
    set((state) => {
      if (!state.items[key]) return state;

      return {
        items: { ...state.items, [key]: value },
      };
    }),

  deleteItem: ({ key }) =>
    set((state) => {
      const { [key]: _, ...remainingItems } = state.items;
      return { items: remainingItems };
    }),
}));

export const useDialogStore = create((set) => ({
  deleteDialog: { isOpen: false, forItem: '', onOk: () => {} },
  editDialog: {
    isOpen: false,
    forItem: '',
    onOk: () => {},
    data: {},
    dialogMode: 'edit',
  },
  setDeleteDialog: ({ isOpen, forItem, onOk }) => {
    set((state) => ({
      deleteDialog: {
        ...state.deleteDialog,
        isOpen,
        forItem,
        onOk,
      },
    }));
  },

  setEditDialog: (updatedProperties) => {
    set((state) => {
      const rest = state.editDialog;
      const updatedData = { ...rest, ...updatedProperties };
      return {
        editDialog: updatedData,
      };
    });
  },
}));

export default useStore;
